import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CircularProgress
} from '@mui/material';

const DeleteConfirmationDialog = ({
    open,
    title = 'Delete Confirmation',
    content = 'Are you sure you want to delete this item? This action cannot be undone.',
    onConfirm,
    onCancel,
    isDeleting = false
}) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
        >
            <DialogTitle id="delete-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="delete-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onCancel}
                    disabled={isDeleting}
                >
                    Cancel
                </Button>
                <Button 
                    onClick={onConfirm}
                    color="error"
                    disabled={isDeleting}
                    startIcon={isDeleting ? <CircularProgress size={20} /> : null}
                    autoFocus
                >
                    {isDeleting ? 'Deleting...' : 'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationDialog;
