import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
    Alert,
    Box,
    Button, Snackbar,
    TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import AddPackageDialog from "./AddNewPackageDialogForm/AddNewPackageDialogForm";
import PackageList from "./PackageList";
import { useDispatch } from "react-redux";
import { fetchPackagesByEventId as fetchPackages } from "../../../redux/packagesSlice";
import { useKeycloak } from '../../../contexts/KeycloakContext';
import { debounce } from 'lodash';

const SearchBar = React.memo(({ handleDialogOpen }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Box sx={{ display: 'flex' }}>
            <Button
                variant='cancel'
                sx={{
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    height: '34px',
                }}
            >
                Active Packages
            </Button>
            <Button
                variant='cancel'
                sx={{
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                    color: 'var(--dark-50, #71717A)',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    lineHeight: '20px',
                    background: 'var(--gray-30, #F7F7F7)',
                }}
            >
                Archived Packages
            </Button>
        </Box>
        <TextField
            variant="outlined"
            placeholder="Search for package"
            InputProps={{
                startAdornment: <SearchIcon sx={{ marginRight: 1 }} />,
            }}
            sx={{ mx: '48px', flexGrow: 1,  }}
        />
        <Box display='flex'>
            <Button variant="cancel" startIcon={<FilterListIcon />} sx={{ marginRight: 1, height: '34px' }}>
                Filters
            </Button>
            <Button variant="cancel" startIcon={<AddIcon />} onClick={handleDialogOpen} sx={{ whiteSpace: 'nowrap', height: '34px' }}>
                Add Packages
            </Button>
        </Box>
    </Box>
));

const AddPackageToEvent = ({ eventId }) => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState('idle');
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [packages, setPackages] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const { isAuthenticated } = useKeycloak();
    const [isRateLimited, setIsRateLimited] = useState(false);

    const debouncedFetchPackages = useCallback(
        debounce(async (showSuccessMessage = false) => {
            if (!isAuthenticated) {
                setError('Authentication required');
                setSnackbar({ open: true, message: 'Please log in to view packages', severity: 'error' });
                return;
            }

            if (!eventId) {
                setError('Event ID is required');
                return;
            }

            if (isRateLimited) {
                return;
            }

            setStatus('loading');
            try {
                const response = await dispatch(fetchPackages(eventId)).unwrap();
                if (response.data) {
                    setPackages(response.data.packages || []);
                    setStatus('succeeded');
                    if (showSuccessMessage) {
                        setSnackbar({ 
                            open: true, 
                            message: 'Package added successfully', 
                            severity: 'success' 
                        });
                    }
                }
            } catch (error) {
                console.error('Error fetching packages:', error);
                let errorMessage = 'Error fetching packages';
                
                if (error?.response?.status === 429) {
                    errorMessage = 'Too many requests. Please wait a moment before trying again.';
                    setIsRateLimited(true);
                    setTimeout(() => setIsRateLimited(false), 5000);
                } else if (error?.message?.message) {
                    errorMessage = error.message.message;
                } else if (error?.message) {
                    errorMessage = error.message;
                } else if (typeof error === 'string') {
                    errorMessage = error;
                }
                
                setError(errorMessage);
                setStatus('failed');
                setSnackbar({ 
                    open: true, 
                    message: errorMessage,
                    severity: 'error' 
                });
            }
        }, 1000),
        [dispatch, eventId, isAuthenticated, isRateLimited]
    );

    useEffect(() => {
        if (eventId && isAuthenticated && !isRateLimited) {
            debouncedFetchPackages(false);
        }
        return () => {
            debouncedFetchPackages.cancel();
        };
    }, [eventId, isAuthenticated, debouncedFetchPackages, isRateLimited]);

    const handleDialogOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleDialogClose = useCallback((success = false) => {
        setOpen(false);
        if (success) {
            debouncedFetchPackages(true);
        }
    }, [debouncedFetchPackages]);

    const handleSnackbarClose = useCallback((event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar(prev => ({ ...prev, open: false }));
    }, []);

    const memoizedPackageCreated = useCallback(() => {
        debouncedFetchPackages(false);
    }, [debouncedFetchPackages]);

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <SearchBar handleDialogOpen={handleDialogOpen} />
            </Box>

            <PackageList 
                handleDialogOpen={handleDialogOpen} 
                eventId={eventId} 
                onPackageCreated={memoizedPackageCreated}
            />

            <AddPackageDialog 
                eventId={eventId} 
                open={open} 
                handleClose={handleDialogClose} 
                onPackageCreated={memoizedPackageCreated}
            />
            
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default React.memo(AddPackageToEvent);
