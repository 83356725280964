import React, {useEffect, useRef, useState, useCallback, useMemo, memo} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Divider, TextField, Typography, Checkbox, InputAdornment, CircularProgress, Snackbar, Alert } from "@mui/material";
import { useKeycloak } from '../../contexts/KeycloakContext';
import { styled } from "@mui/system";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { fetchOrganizations, patchOrganization } from "../../redux/organizationSlice";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const StoreSetupForm = React.memo(({ onSaveSuccess, onFormValidityChange, onFormDataChange, showEditButton = true }) => {
    const dispatch = useDispatch();
    const pathInputRef = useRef(null);
    const { userInfo, isAuthenticated } = useKeycloak();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [organizationId, setOrganizationId] = useState(null);

    useEffect(() => {
        if (isAuthenticated && userInfo) {
            setOrganizationId(userInfo.organization_id);
        }
    }, [isAuthenticated, userInfo]);

    const { organization, status, error } = useSelector((state) => state.organizations);
    const [formData, setFormData] = useState({
        id: '',
        storeName: '',
        storeUrl: '',
        subdomain: '',
        contactNumber: '',
        emailAddress: '',
        storeDescription: '',
    });

    const [operatingHours, setOperatingHours] = useState({
        Sunday: { isOpen: false, openTime: '', closeTime: '' },
        Monday: { isOpen: false, openTime: '', closeTime: '' },
        Tuesday: { isOpen: false, openTime: '', closeTime: '' },
        Wednesday: { isOpen: false, openTime: '', closeTime: '' },
        Thursday: { isOpen: false, openTime: '', closeTime: '' },
        Friday: { isOpen: false, openTime: '', closeTime: '' },
        Saturday: { isOpen: false, openTime: '', closeTime: '' },
    });

    useEffect(() => {
        dispatch(fetchOrganizations());
    }, [dispatch]);

    useEffect(() => {
        if (organization) {
            const formattedData = {
                id: organization.id,
                storeName: organization.store_name || '',
                storeUrl: organization.store_url || '',
                subdomain: organization.store_url ? organization.store_url.split('.')[0] : '',
                contactNumber: organization.contact_number || '',
                emailAddress: organization.email_address || '',
                storeDescription: organization.store_description || '',
            };

            setFormData(formattedData);

            if (organization.operating_hours) {
                const formattedHours = {};
                Object.entries(organization.operating_hours).forEach(([day, hours]) => {
                    formattedHours[day] = {
                        isOpen: hours.isOpen || false,
                        openTime: hours.openTime || '10:00',
                        closeTime: hours.closeTime || '22:00'
                    };
                });
                setOperatingHours(formattedHours);
            }
            
            // Notify parent of initial data
            if (onFormDataChange) {
                onFormDataChange({
                    ...formattedData,
                    operatingHours: organization.operating_hours || {}
                });
            }
        }
    }, [organization, onFormDataChange]);

    const generateUrlFriendlyString = useCallback((str) => {
        return str.toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }, []);

    const handleInputChange = useCallback((nameOrEvent, value) => {
        let fieldName, fieldValue;
        
        // Check if this is an event or direct values
        if (nameOrEvent && nameOrEvent.target) {
            // It's an event
            fieldName = nameOrEvent.target.name;
            fieldValue = nameOrEvent.target.value;
        } else {
            // It's direct values
            fieldName = nameOrEvent;
            fieldValue = value;
        }

        let newErrors = { ...errors };

        // Validate subdomain
        if (fieldName === 'subdomain') {
            const subdomainRegex = /^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$/;
            if (!fieldValue) {
                newErrors.subdomain = 'Subdomain is required';
            } else if (!subdomainRegex.test(fieldValue)) {
                newErrors.subdomain = 'Subdomain must be 2-63 characters long, start and end with alphanumeric characters, and may contain hyphens';
            } else {
                delete newErrors.subdomain;
            }
        }

        setErrors(newErrors);
        setFormData(prevData => {
            const newData = {
                ...prevData,
                [fieldName]: fieldValue
            };
            if (onFormDataChange) {
                onFormDataChange(newData);
            }
            return newData;
        });
    }, [errors, onFormDataChange]);

    const handleStoreNameChange = useCallback((e) => {
        const newStoreName = e.target.value;
        const newStoreLinkPath = generateUrlFriendlyString(newStoreName);
        setFormData(prevData => {
            const newData = {
                ...prevData,
                storeName: newStoreName,
                storeLinkPath: newStoreLinkPath
            };
            if (onFormDataChange) {
                onFormDataChange({
                    ...newData,
                    operatingHours: operatingHours
                });
            }
            return newData;
        });
    }, [generateUrlFriendlyString, onFormDataChange, operatingHours]);

    const handleOperatingHoursChange = useCallback((day, field, value) => {
        setOperatingHours(prevHours => {
            const updatedHours = { ...prevHours };
            if (field === 'isOpen') {
                updatedHours[day] = {
                    ...updatedHours[day],
                    isOpen: value,
                    openTime: value ? (updatedHours[day]?.openTime || '10:00') : '',
                    closeTime: value ? (updatedHours[day]?.closeTime || '22:00') : ''
                };
            } else {
                updatedHours[day] = {
                    ...updatedHours[day] || {},
                    [field]: value
                };
            }
            if (onFormDataChange) {
                onFormDataChange({
                    ...formData,
                    operatingHours: updatedHours
                });
            }
            return updatedHours;
        });
    }, [onFormDataChange, formData]);

    const handleStoreLinkPathChange = useCallback((e) => {
        const newPath = e.target.value.replace(/[^a-z0-9-]/g, '');
        handleInputChange('storeLinkPath', newPath);
    }, [handleInputChange]);

    const handleContactNumberChange = useCallback((e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        handleInputChange('contactNumber', value);
    }, [handleInputChange]);

    const handleEmailChange = useCallback((e) => {
        handleInputChange('emailAddress', e.target.value);
    }, [handleInputChange]);

    const isValidEmail = useCallback((email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }, []);

    const focusPathInput = useCallback(() => {
        if (pathInputRef.current) {
            pathInputRef.current.focus();
        }
    }, []);

    const isTimeValid = useCallback((time) => {
        if (!time) return true;
        const [hours, minutes] = time.split(':');
        return parseInt(hours) >= 0 && parseInt(hours) < 24 && parseInt(minutes) >= 0 && parseInt(minutes) < 60;
    }, []);

    const formatTime = useCallback((time) => {
        return time || '00:00';
    }, []);

    const dayNames = useMemo(() => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], []);

    const handleConfirm = useCallback(async () => {
        setIsLoading(true);
        try {
            if (!organizationId) {
                throw new Error('Organization ID is required');
            }

            const apiData = {
                id: formData.id,
                store_name: formData.storeName,
                store_url: `${formData.subdomain}.eventlah.com`,
                contact_number: formData.contactNumber,
                email_address: formData.emailAddress,
                store_description: formData.storeDescription,
                operating_hours: operatingHours,
                organization_id: organizationId
            };

            const result = await dispatch(patchOrganization(apiData)).unwrap();

            setSnackbar({
                open: true,
                message: 'Store details saved successfully!',
                severity: 'success'
            });
            if (onSaveSuccess) {
                onSaveSuccess();
            }
        } catch (error) {
            console.error('Failed to save store details:', error);
            setSnackbar({
                open: true,
                message: 'Failed to save store details. Please try again.',
                severity: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, formData, operatingHours, onSaveSuccess]);

    const handleSnackbarClose = useCallback((event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar(prev => ({ ...prev, open: false }));
    }, []);

    const isFormValid = useMemo(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const hasValidOperatingHours = Object.values(operatingHours).some(day => day.isOpen);
        
        return (
            formData.storeName?.trim() !== '' &&
            formData.storeName?.trim().length >= 3 &&
            formData.storeDescription?.trim() !== '' &&
            formData.storeDescription?.trim().length >= 10 &&
            formData.subdomain?.trim() !== '' &&
            formData.contactNumber?.trim() !== '' &&
            formData.contactNumber?.trim().length >= 10 &&
            emailRegex.test(formData.emailAddress?.trim() || '') &&
            hasValidOperatingHours
        );
    }, [formData.storeName, formData.storeDescription, formData.subdomain, 
        formData.contactNumber, formData.emailAddress, operatingHours]);

    useEffect(() => {
        if (onFormValidityChange) {
            onFormValidityChange(isFormValid);
        }
    }, [isFormValid, onFormValidityChange]);

    // Render logic remains largely unchanged
    // ...

    return (
        <Box>
            <Box component="form" noValidate autoComplete="off">

                {/* Store Name */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>
                        Store Name <RequiredIndicator>*</RequiredIndicator>
                    </TextFieldTitle>
                    <TextField
                        label="Enter store name"
                        placeholder="Store name"
                        value={formData.storeName || ''}
                        onChange={handleStoreNameChange}
                        fullWidth
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Store URL */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>
                        Store URL <RequiredIndicator>*</RequiredIndicator>
                    </TextFieldTitle>
                    <TextField
                        fullWidth
                        required
                        name="subdomain"
                        value={formData.subdomain}
                        onChange={handleInputChange}
                        error={!!errors.subdomain}
                        helperText={
                            errors.subdomain || 
                            <Typography component="div" sx={{ mt: 1 }}>
                                This will be your store's URL: <strong>{formData.subdomain || 'subdomain'}.eventlah.com</strong>
                            </Typography>
                        }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">.eventlah.com</InputAdornment>,
                        }}
                        placeholder="Enter your desired subdomain"
                        sx={{ 
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: errors.subdomain ? 'error.main' : 'grey.300',
                                },
                            },
                            '& .MuiFormHelperText-root': {
                                color: errors.subdomain ? 'error.main' : 'text.primary',
                            }
                        }}
                    />
                </Box>

                <Divider />

                {/* Store Description */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>Store Description</TextFieldTitle>
                    <TextField
                        label="Enter store description"
                        value={formData.storeDescription || ''}
                        onChange={(e) => handleInputChange('storeDescription', e.target.value)}
                        fullWidth
                        multiline
                        rows={7}
                        inputProps={{ maxLength: 250 }}
                        helperText={`${(formData.storeDescription || '').length}/250`}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '15px',
                                '& .MuiOutlinedInput-input': {
                                    padding: 0,
                                },
                            },
                            paddingRight: { md: '50px', lg: '240px' }
                        }}
                    />
                </Box>

                <Divider />

                {/* Contact Number */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>
                        Contact Number <RequiredIndicator>*</RequiredIndicator>
                    </TextFieldTitle>
                    <TextField
                        label="Enter a phone number to contact the store"
                        placeholder="0123456789"
                        value={formData.contactNumber || ''}
                        onChange={handleContactNumberChange}
                        fullWidth
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* ConfirmationEmail Address */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>
                        Email Address <RequiredIndicator>*</RequiredIndicator>
                    </TextFieldTitle>
                    <TextField
                        label="Enter email contact for the store"
                        placeholder="you@example.com"
                        value={formData.emailAddress || ''}
                        onChange={handleEmailChange}
                        error={formData.emailAddress !== '' && !isValidEmail(formData.emailAddress)}
                        helperText={formData.emailAddress !== '' && !isValidEmail(formData.emailAddress) ? 'Invalid email format' : ''}
                        fullWidth
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Operating Hours */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>Operating Hours</TextFieldTitle>
                    <Box sx={{ width: '100%', paddingRight: { md: '50px', lg: '240px' } }}>
                        {dayNames.map((day) => (
                            <Box key={day} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Checkbox
                                    checked={operatingHours[day]?.isOpen || false}
                                    onChange={(e) => handleOperatingHoursChange(day, 'isOpen', e.target.checked)}
                                />
                                <Typography sx={{ width: '200px' }}>{day}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                                    <TextField
                                        type="time"
                                        value={formatTime(operatingHours[day]?.openTime)}
                                        onChange={(e) => handleOperatingHoursChange(day, 'openTime', e.target.value)}
                                        disabled={!operatingHours[day]?.isOpen}
                                        sx={{ width: '150px' }}
                                        InputProps={{ inputProps: { step: 300 } }}
                                    />
                                    <Typography sx={{ mx: 1 }}>-</Typography>
                                    <TextField
                                        type="time"
                                        value={formatTime(operatingHours[day]?.closeTime)}
                                        onChange={(e) => handleOperatingHoursChange(day, 'closeTime', e.target.value)}
                                        disabled={!operatingHours[day]?.isOpen}
                                        sx={{ width: '150px' }}
                                        InputProps={{ inputProps: { step: 300 } }}
                                    />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Divider />
            </Box>

            {/* Button Group */}
            <Box sx={{ display: 'flex', gap: '12px', justifyContent: { xs: 'center', md: 'flex-start' }, mt: 5 }}>
                {showEditButton && (
                    <Button
                        variant="save"
                        onClick={handleConfirm}
                        sx={{
                            textTransform: 'none',
                            height: '34px',
                            minWidth: '80px',
                            '&:disabled': {
                                color: '#FFF',
                            }
                        }}
                        disabled={!isFormValid || isLoading}
                    >
                        {isLoading ? (
                            <>
                                <CircularProgress size={16} />
                                Loading...
                            </>
                        ) : (
                            formData.id ? "Edit" : "Save"
                        )}
                    </Button>
                )}
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
});

export default StoreSetupForm;
