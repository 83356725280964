import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Button,
    Popover,
    Checkbox,
    FormControlLabel,
    Link,
    CircularProgress,
    Alert,
    Snackbar,
} from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { styled } from '@mui/system';
import { ChromePicker } from 'react-color';
import { CompanyLogo } from "../../../header/HeaderSvgImages";
import {
    fetchThemeComponents,
    updateThemeComponent,
    selectAllThemeComponents,
    selectThemeComponentsStatus,
    selectThemeComponentsError,
    selectUpdateStatus,
    selectUpdateError,
    resetUpdateStatus
} from '../../../../redux/themeComponentsSlice';
import { useKeycloak } from '../../../../contexts/KeycloakContext';
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';
import { fetchOrganizationPublicDetails } from "../../../../redux/organizationPublicDetailsSlice";

const s3Client = new S3Client({
    region: "ap-southeast-5",
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_BITBUCKET_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_BITBUCKET_SECRET_ACCESS_KEY
    },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),
}));

const ColorBox = styled(Box)(({ bgcolor }) => ({
    width: '24px',
    height: '24px',
    backgroundColor: bgcolor,
    cursor: 'pointer',
    border: '1px solid #000',
    borderRadius: '50%',
    marginRight: '8px',
}));

const defaultFormData = {
    logo: <CompanyLogo/>,
    name: '',
    mainText: '',
    description: '',
    content: '',
    button1: '',
    footerText: '',
    button2: '',
    font: '',
    companyName: '',
    companyAddress: '',
    businessWebsite: '',
    showCompanyName: false,
    showCompanyAddress: false,
    showBusinessWebsite: false,
    showQRCodeScanner: false,
    colors: {
        body: {
            text: '#000000',
            buttonsAndLinks: '#FEE000',
            buttonLabelsAndOutlines: '#232323',
        },
        background: {
            background1: '#f5f5f5',
            background2: '#FEE000',
        },
        footer: {
            textAndIcons: '#232323',
            background: '#FFF',
        },
    },
};

const TicketTab = () => {
    const dispatch = useDispatch();
    const { userInfo } = useKeycloak();
    const themeComponents = useSelector(selectAllThemeComponents);
    const fetchStatus = useSelector(selectThemeComponentsStatus);
    const fetchError = useSelector(selectThemeComponentsError);
    const updateStatus = useSelector(selectUpdateStatus);
    const updateError = useSelector(selectUpdateError);

    const [isEditing, setIsEditing] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [qrValue, setQrValue] = useState('https://eventlah.com');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [formData, setFormData] = useState(defaultFormData);
    const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
    const [activeColorField, setActiveColorField] = useState(null);

    const organizationPublicDetails = useSelector((state) => state.organizationPublicDetails.details);

    // Fetch initial data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const organizationId = userInfo?.organization_id;
                if (!organizationId) {
                    throw new Error('Organization ID not found');
                }

                await dispatch(fetchOrganizationPublicDetails(organizationId));
                await dispatch(fetchThemeComponents({
                    page: 1,
                    limit: 10,
                    organization_id: organizationId
                }));
            } catch (error) {
                console.error('Failed to fetch initial data:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Failed to load data. Please refresh the page.');
                setSnackbarOpen(true);
            }
        };

        if (fetchStatus === 'idle' && userInfo) {
            fetchData();
        }
    }, [dispatch, fetchStatus, userInfo]);

    // Update form with organization details
    useEffect(() => {
        if (organizationPublicDetails?.length > 0) {
            const details = organizationPublicDetails[0]?.data;
            setFormData(prevData => ({
                ...prevData,
                companyName: details?.business_name || '',
                companyAddress: [
                    details?.customer_support_address_line_1,
                    details?.customer_support_address_line_2,
                    details?.postal_code,
                    details?.city,
                    details?.state
                ].filter(Boolean).join(', '),
                businessWebsite: details?.business_website || '',
            }));
        }
    }, [organizationPublicDetails]);

    // Update form with theme component data
    useEffect(() => {
        if (themeComponents && themeComponents.length > 0) {
            const ticketComponent = themeComponents.find(component => component.name === 'ticket');
            if (ticketComponent) {
                // Only update if we're not currently editing
                if (!isEditing) {
                    const newFormData = {
                        ...defaultFormData,
                        mainText: ticketComponent.main_text || '',
                        description: ticketComponent.description || '',
                        font: ticketComponent.typography || '',
                        showCompanyName: ticketComponent.show_company_name || false,
                        showCompanyAddress: ticketComponent.show_company_address || false,
                        showBusinessWebsite: ticketComponent.show_business_website || false,
                        colors: {
                            body: {
                                text: ticketComponent.body_text_color || '#333333',
                                buttonsAndLinks: ticketComponent.button_color || '#ffeb3b',
                                buttonLabelsAndOutlines: ticketComponent.button_label_color || '#434040',
                            },
                            background: {
                                background1: ticketComponent.background_color_1 || '#FFFFFF',
                                background2: ticketComponent.background_color_2 || '#F5F5F5',
                            },
                            footer: {
                                textAndIcons: ticketComponent.footer_text_color || '#666666',
                                background: ticketComponent.footer_background_color || '#F9F9F9',
                            },
                        },
                        content: ticketComponent.other_text_1 || '',
                        button1: ticketComponent.other_text_2 || '',
                        footerText: ticketComponent.other_text_3 || '',
                        button2: ticketComponent.other_text_4 || '',
                        logo: ticketComponent.logo || <CompanyLogo/>,
                    };

                    // Compare new form data with current form data
                    const hasChanges = JSON.stringify(newFormData) !== JSON.stringify(formData);
                    setIsEditing(hasChanges);
                    setFormData(newFormData);
                }
            }
        }
    }, [themeComponents, isEditing, formData]);

    // Monitor update status
    useEffect(() => {
        if (updateStatus === 'succeeded') {
            setSnackbarSeverity('success');
            setSnackbarMessage('Changes saved successfully!');
            setSnackbarOpen(true);
            setIsEditing(false);
            dispatch(resetUpdateStatus());
        } else if (updateStatus === 'failed') {
            setSnackbarSeverity('error');
            setSnackbarMessage(updateError || 'Failed to save changes.');
            setSnackbarOpen(true);
            // Don't reset isEditing on failure so user can try again
            dispatch(resetUpdateStatus());
        }
    }, [updateStatus, updateError, dispatch]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => {
            const newData = {
                ...prevData,
                [name]: value,
            };
            // Compare with original data
            const originalComponent = themeComponents.find(component => component.name === 'ticket');
            const hasChanges = originalComponent && (
                value !== (originalComponent[name] || '')
            );
            setIsEditing(hasChanges);
            return newData;
        });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevData => {
            const newData = {
                ...prevData,
                [name]: checked,
            };
            // Compare with original data
            const originalComponent = themeComponents.find(component => component.name === 'ticket');
            const hasChanges = originalComponent && (
                checked !== (originalComponent[name] || false)
            );
            setIsEditing(hasChanges);
            return newData;
        });
    };

    const handleColorChange = (color) => {
        if (!activeColorField) return;

        setFormData(prevData => {
            const newData = {
                ...prevData,
                colors: {
                    ...prevData.colors,
                    [activeColorField.group]: {
                        ...prevData.colors[activeColorField.group],
                        [activeColorField.field]: color.hex,
                    },
                },
            };
            // Compare with original data
            const originalComponent = themeComponents.find(component => component.name === 'ticket');
            const hasChanges = originalComponent && (
                color.hex !== (originalComponent[`${activeColorField.group}_${activeColorField.field}_color`] || '')
            );
            setIsEditing(hasChanges);
            return newData;
        });
    };

    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setUploading(true);
        try {
            const timestamp = Date.now();
            const uniqueId = uuidv4().slice(0, 6);
            const fileNameParts = file.name.split('.');
            const fileExtension = fileNameParts.pop();
            const fileName = fileNameParts.join('.');
            const uniqueFileName = `${fileName}_${timestamp}_${uniqueId}.${fileExtension}`;
            const organizationId = userInfo?.organization_id || '1';
            const key = `${organizationId}/${uniqueFileName}`;

            const params = {
                Bucket: "my.merchants",
                Key: key,
                Body: file,
                ContentType: file.type,
            };

            await s3Client.send(new PutObjectCommand(params));
            const fileUrl = `https://s3.ap-southeast-5.amazonaws.com/my.merchants/${encodeURIComponent(key)}`;

            const ticketComponent = themeComponents.find(component => component.name === 'ticket');
            if (ticketComponent) {
                await dispatch(updateThemeComponent({
                    id: ticketComponent.id,
                    themeComponentData: { logo: fileUrl }
                }));

                setFormData(prevData => ({
                    ...prevData,
                    logo: fileUrl,
                }));
                setIsEditing(true);

                setSnackbarSeverity('success');
                setSnackbarMessage('Logo uploaded successfully!');
                setSnackbarOpen(true);
            }
        } catch (err) {
            console.error("Error uploading file:", err);
            setSnackbarSeverity('error');
            setSnackbarMessage('Failed to upload logo.');
            setSnackbarOpen(true);
        } finally {
            setUploading(false);
        }
    };

    const handleSave = async () => {
        const organizationId = userInfo?.organization_id;
        if (!organizationId) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Organization ID not found. Please try logging in again.');
            setSnackbarOpen(true);
            return;
        }

        const ticketComponent = themeComponents.find(component => component.name === 'ticket');
        if (!ticketComponent) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Ticket component not found.');
            setSnackbarOpen(true);
            return;
        }

        try {
            const themeComponentData = {
                organization_id: organizationId,
                name: 'ticket',
                main_text: formData.mainText,
                description: formData.description,
                typography: formData.font,
                show_company_name: formData.showCompanyName,
                show_company_address: formData.showCompanyAddress,
                show_business_website: formData.showBusinessWebsite,
                body_text_color: formData.colors.body.text,
                button_color: formData.colors.body.buttonsAndLinks,
                button_label_color: formData.colors.body.buttonLabelsAndOutlines,
                background_color_1: formData.colors.background.background1,
                background_color_2: formData.colors.background.background2,
                footer_text_color: formData.colors.footer.textAndIcons,
                footer_background_color: formData.colors.footer.background,
                other_text_1: formData.content,
                other_text_2: formData.button1,
                other_text_3: formData.footerText,
                other_text_4: formData.button2,
                logo: typeof formData.logo === 'string' ? formData.logo : null,
            };

            console.log('Sending update with data:', themeComponentData);

            const result = await dispatch(updateThemeComponent({
                id: ticketComponent.id,
                themeComponentData
            })).unwrap();

            if (result.code === 200 || result.code === 201) {
                setSnackbarSeverity('success');
                setSnackbarMessage('Theme updated successfully!');
                setSnackbarOpen(true);

                // Refresh the data
                await dispatch(fetchThemeComponents({
                    page: 1,
                    limit: 10,
                    organization_id: organizationId
                }));
            } else {
                throw new Error(result.description || 'Update failed');
            }
        } catch (error) {
            console.error('Save error:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage(error.message || 'Failed to update theme component.');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const renderColorOption = (group, field, label, allowNone = false) => {
        const color = formData.colors[group][field];
        return (
            <Box display="flex" alignItems="center" mb={1}>
                <ColorBox
                    bgcolor={color || 'transparent'}
                    onClick={(e) => handleColorBoxClick(e, group, field)}
                />
                <div variant="body2">
                    {label}
                    {color ? ` ${color}` : ' None'}
                </div>
            </Box>
        );
    };

    const fontOptions = [
        'Arial', 'Helvetica', 'Verdana', 'Trebuchet MS', 'Gill Sans',
        'Noto Sans', 'Avantgarde', 'Optima', 'Arial Narrow', 'sans-serif',
        'Times', 'Times New Roman', 'Didot', 'Georgia', 'Palatino',
        'Bookman', 'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 'Impact'
    ];

    if (fetchStatus === 'loading') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (fetchStatus === 'failed') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <div color="error">Error: {fetchError}</div>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                minHeight: '100vh',
            }}
        >
            {/* Form Section */}
            <StyledPaper
                elevation={3}
                sx={{
                    flex: { xs: 1, md: 0.8 },
                    overflowY: 'auto',
                    borderRadius: '12px',
                    p: { xs: 2, md: 3 },
                }}
            >
                {/* Logo Upload Section */}
                <div sx={{ fontSize: '16px', fontWeight: '700', mb: 1 }}>
                    Logo
                </div>
                <div sx={{ fontSize: '12px', mb: 1 }}>
                    Recommended image size: 250px x 250px
                </div>
                <br />
                <label htmlFor="raised-button-file">
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleLogoUpload}
                    />
                    <Button
                        variant="save"
                        component="span"
                        disabled={uploading || updateStatus === 'loading'}
                    >
                        {uploading ? <CircularProgress size={24} /> : 'Upload Logo'}
                    </Button>
                </label>

                {/* Main Text Section */}
                <div sx={{ fontSize: '16px', fontWeight: '700', mt: 4, mb: 1 }}>
                    Main Text
                </div>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="mainText"
                    value={formData.mainText}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                />

                {/* Description Section */}
                <div sx={{ fontSize: '16px', fontWeight: '700', mt: 2, mb: 1 }}>
                    Description
                </div>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                />

                {/* Typography Section */}
                <div sx={{ fontSize: '16px', fontWeight: '700', mt: 2, mb: 1 }}>
                    Typography
                </div>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel>Font</InputLabel>
                    <Select
                        value={formData.font}
                        onChange={handleInputChange}
                        label="Font"
                        name="font"
                    >
                        {fontOptions.map((font) => (
                            <MenuItem key={font} value={font}>
                                {font}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Company Information Section */}
                <div sx={{ fontSize: '16px', fontWeight: '700', mt: 2, mb: 1 }}>
                    Company Information
                    <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
                        If you want to change your business details,{' '}
                        <Link
                            href="/settings/profile-settings#public-details"
                            underline="always"
                            sx={{ color: 'black', '&:hover': { color: 'black' }, fontWeight: 'bold' }}
                        >
                            click here
                        </Link>
                    </Alert>
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showCompanyName}
                                onChange={handleCheckboxChange}
                                name="showCompanyName"
                            />
                        }
                        label="Show Company Name"
                        sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showCompanyAddress}
                                onChange={handleCheckboxChange}
                                name="showCompanyAddress"
                            />
                        }
                        label="Show Company Address"
                        sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showBusinessWebsite}
                                onChange={handleCheckboxChange}
                                name="showBusinessWebsite"
                            />
                        }
                        label="Show Business Website"
                        sx={{ marginLeft: 0 }}
                    />
                </Box>

                {/* Colors Section */}
                <div sx={{ fontSize: '16px', fontWeight: '700', mt: 2, mb: 1 }}>
                    Colors
                </div>
                <Box mb={2}>
                    <div variant="subtitle1">Body</div>
                    {renderColorOption('body', 'text', 'Text')}
                    {renderColorOption('body', 'buttonsAndLinks', 'Buttons and links')}
                    {renderColorOption('body', 'buttonLabelsAndOutlines', 'Button labels and outlines')}
                </Box>
                <Box mb={2}>
                    <div variant="subtitle1">Background</div>
                    {renderColorOption('background', 'background1', 'Background 1')}
                    {renderColorOption('background', 'background2', 'Background 2')}
                </Box>
                <Box mb={2}>
                    <div variant="subtitle1">Footer</div>
                    {renderColorOption('footer', 'textAndIcons', 'Text and icons', true)}
                    {renderColorOption('footer', 'background', 'Background', true)}
                </Box>

                {/* Other Texts Section */}
                <div sx={{ fontSize: '16px', fontWeight: '700', mt: 2, mb: 1 }}>
                    Other Texts
                </div>
                {[
                    { name: 'footerText', label: 'Footer Text' },
                    { name: 'button2', label: 'Button 2' },
                ].map(({ name, label }) => (
                    <TextField
                        key={name}
                        fullWidth
                        variant="outlined"
                        name={name}
                        label={label}
                        value={formData[name]}
                        onChange={handleInputChange}
                        sx={{ mb: 2 }}
                    />
                ))}

                {/* Save Button */}
                <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={!isEditing || updateStatus === 'loading'}
                    sx={{ mt: 2 }}
                >
                    {updateStatus === 'loading' ? <CircularProgress size={24} /> : 'Save Changes'}
                </Button>
            </StyledPaper>

            {/* Preview Section */}
            <StyledPaper
                elevation={3}
                sx={{
                    flex: { xs: 1, md: 1 }, // Full width on mobile, partial width on larger screens
                    borderRadius: '12px',
                    mt: { xs: 3, md: 0 }, // Add margin-top for mobile
                    p: { xs: 2, md: 3 }, // Adjust padding for mobile
                }}
            >
                <Box
                    sx={{
                        bgcolor: formData.colors.background.background1,
                        p: 2,
                        mb: 6,
                        mt: 3,
                        textAlign: 'center',
                        borderRadius: '12px',
                        fontFamily: formData.font,
                    }}
                >
                    <Box sx={{ mb: 3 }}>
                        {typeof formData.logo === 'string' ? (
                            <img
                                src={formData.logo}
                                alt="Company Logo"
                                style={{ width: '250px', maxWidth: '100%', height: 'auto' }}
                            />
                        ) : (
                            formData.logo
                        )}
                    </Box>

                    {formData.showCompanyName && (
                        <div
                            variant="h6"
                            sx={{
                                color: formData.colors.body.text,
                                fontFamily: 'inherit',
                            }}
                        >
                            {formData.companyName}
                        </div>
                    )}
                    {formData.showCompanyAddress && (
                        <div
                            variant="body2"
                            sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}
                        >
                            {formData.companyAddress}
                        </div>
                    )}
                    {formData.showBusinessWebsite && (
                        <Link
                            href={formData.businessWebsite}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                color: formData.colors.body.buttonLabelsAndOutlines,
                                fontFamily: 'inherit',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {formData.businessWebsite}
                        </Link>
                    )}
                    <Box sx={{ backgroundColor: formData.colors.body.text, height: '1px', mx: 'auto', mb: 3, mt: 2 }} />
                    <div variant="h4" sx={{ color: formData.colors.body.text, mb: 1, fontFamily: 'inherit' }}>
                        {formData.mainText}
                    </div>
                    <div variant="body1" sx={{ color: formData.colors.body.text, fontFamily: 'inherit', pb: 2 }}>
                        {formData.description}
                    </div>
                </Box>

                <Box sx={{ bgcolor: formData.colors.footer.background || 'transparent', color: formData.colors.footer.textAndIcons || formData.colors.body.text, p: 2, mt: 2, textAlign: 'center', fontFamily: formData.font }}>
                    <div variant="h6" sx={{ fontFamily: 'inherit' }}>
                        Reservation details: <br />
                        1 x Adult
                    </div>
                </Box>

                <Grid container spacing={3} sx={{mt:0, mb:'30px'}}>
                    <Grid item xs={12}>
                        <Paper
                            elevation={2}
                            sx={{
                                p: 2,
                                borderRadius: '12px',
                                height: 250,
                                width: 250,
                                mx: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // bgcolor: formData.colors.background.background2,
                                fontFamily: formData.font,
                            }}
                        >
                            <div variant="h6" sx={{ color: formData.colors.body.text, fontFamily: 'inherit', mb: 2 }}>
                                1 Adult
                            </div>
                            <QRCodeSVG value={qrValue} size={150}
                                       // bgColor={formData.colors.background.background2}
                                       fgColor={formData.colors.body.text} />
                        </Paper>
                    </Grid>
                </Grid>

                <Box sx={{ bgcolor: formData.colors.footer.background || 'transparent', color: formData.colors.footer.textAndIcons || formData.colors.body.text, p: 2, mt: 2, textAlign: 'center', fontFamily: formData.font }}>
                    <div variant="h6" sx={{ fontFamily: 'inherit' }}>
                        {formData.footerText}
                    </div>
                    <Button
                        variant="contained"
                        sx={{
                            mt: 1,
                            bgcolor: formData.colors.body.buttonsAndLinks,
                            color: formData.colors.body.buttonLabelsAndOutlines,
                            fontFamily: 'inherit',
                            '&:hover': {
                                bgcolor: formData.colors.body.buttonsAndLinks,
                                opacity: 0.9,
                            },
                        }}
                    >
                        {formData.button2}
                    </Button>
                </Box>
            </StyledPaper>

            {/* Color Picker Popover */}
            <Popover
                open={Boolean(colorPickerAnchor)}
                anchorEl={colorPickerAnchor}
                onClose={() => {
                    setColorPickerAnchor(null);
                    setActiveColorField(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ChromePicker
                    color={activeColorField ? formData.colors[activeColorField.group][activeColorField.field] : '#fff'}
                    onChange={handleColorChange}
                />
            </Popover>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default TicketTab;