import React, {useState, useEffect} from 'react';
import {Grid, Card, CardContent, Typography, Box, Alert} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { varAlpha } from '../../theme/styles';
import { alpha } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { useOrganizationRoles } from "../shared/useOrganizationRoles";
import { useKeycloak } from '../../contexts/KeycloakContext';
import { useUserInfo } from '../../hooks/useUserInfo';
import { authService } from '../../services/auth.service';

import {
    AccountBox as AccountBoxIcon,
    Email as EmailIcon,
    Public as PublicIcon,
    Business as BusinessIcon,
    Description as DescriptionIcon,
    ViewAgenda as PlanIcon,
    Security as SecurityIcon,
    Assessment as AssessmentIcon,
    Store as StorefrontIcon,
    PersonAddAlt1 as PersonAddAlt1Icon,
    Payment as PaymentIcon,
    UploadFile as UploadFileIcon,
    ShoppingCart as ShoppingCartIcon,
    ConfirmationNumber as TicketingIcon,
    RoomPreferences as RoomPreferencesIcon,
    LocalOffer as LocalOfferIcon,
    LocalShipping as LocalShippingIcon,
    Assignment as AssignmentIcon,
    CalendarToday as CalendarTodayIcon,
    People as PeopleIcon,
    Person as PersonIcon,
    Info as InfoIcon,
    ManageAccounts as ManageAccountsIcon,
    Bookmark as BookmarkIcon,
    History as HistoryIcon,
    Contacts as ContactsIcon,
    Receipt as BillingIcon,
    AttachMoney as AttachMoneyIcon,
    PriceCheck as PriceCheckIcon
} from '@mui/icons-material';
import theme from "../../theme";

const DescriptionTypography = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-500, #667085)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    marginTop: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    maxHeight: '54px',
}));

const SettingsPage = () => {
    const navigate = useNavigate();
    const organizationRoles = useOrganizationRoles();
    const { userInfo } = useUserInfo();
    const { isAuthenticated, isLoading } = useKeycloak();

    useEffect(() => {
        const initializeSettings = async () => {
            if (!isAuthenticated || isLoading) return;

            try {
                // Ensure valid token for API calls
                await authService.ensureValidToken();
                
                // Get organization ID from user info
                const organizationId = userInfo?.organization_id;
                if (!organizationId) {
                    console.error('No organization ID found');
                    return;
                }

                // You can now use organizationId for API calls
                // Example: await fetchSettingsData(organizationId);
            } catch (error) {
                console.error('Error initializing settings:', error);
            }
        };

        initializeSettings();
    }, [isAuthenticated, isLoading, userInfo]);

    const businessSettings = [
        { icon: <AccountBoxIcon />, title: 'Profile Settings', description: 'Manages user information, bussiness public details', path: 'profile-settings', roles: ['super_admin', 'org_admin', 'merchants'] },
        { icon: <StorefrontIcon />, title: 'Store Configurations', description: 'Configure store details', path: 'store-setup', roles: ['super_admin', 'org_admin', 'merchants', 'product_manager'] },
        { icon: <PublicIcon />, title: 'Landing Page', description: 'Design, manage and publish your landing page', path: 'landing-page', roles: ['super_admin', 'org_admin', 'merchants', 'marketing_manager'] },
        { icon: <BusinessIcon />, title: 'Branding', description: 'Customised your business branding', path: 'branding', roles: ['super_admin', 'org_admin', 'merchants'] },
        { icon: <EmailIcon />, title: 'Email Notification', description: 'Set up and manage email notifications', path: 'email-notifications', roles: ['super_admin', 'org_admin', 'merchants'] },
        { icon: <DescriptionIcon />, title: 'Compliance and Documents', description: 'Your eKYC, SSM documents and Term of Service Agreements', path: 'compliance-documents', roles: ['super_admin', 'merchants', 'compliance_officer'] },
        { icon: <SecurityIcon />, title: 'Team and Security', description: 'Manage team members and roles permissions', path: 'team-security', roles: ['super_admin', 'org_admin', 'merchants'] },
        { icon: <AssessmentIcon />, title: 'Account Status', description: 'Check the status of your account', path: 'account-status', roles: ['super_admin', 'org_admin', 'merchants'] },
        { icon: <PublicIcon />, title: 'Domain', description: 'Set up and domain verifications', path: 'domain-setup', roles: ['super_admin', 'org_admin', 'merchants'] },
        { icon: <PersonAddAlt1Icon />, title: 'Sales Channels', description: 'Manage and configure your agents for affiliate purposes', path: 'agent-setup', roles: ['super_admin', 'org_admin', 'merchants'] },
        { icon: <PaymentIcon />, title: 'Payment', description: 'Set up and manage payment methods and transactions', path: 'payment', roles: ['super_admin', 'org_admin', 'merchants', 'finance_manager'] },
        { icon: <UploadFileIcon />, title: 'Uploads', description: 'Manage uploaded files and media content.', path: 'uploads', roles: ['super_admin', 'org_admin', 'merchants', 'content_manager'] },
        { icon: <PeopleIcon />, title: 'Customers', description: 'View and manage customer records', path: 'customer-records' },
    ];

    const productSettings = [
        // { icon: <ShoppingCartIcon />, title: 'Product Setup', description: 'Manage your products stocks and inventory', path: 'product-catalog' },
        { icon: <TicketingIcon />, title: 'Configure Ticketing', description: 'Create, manage your tickets', path: 'ticketing-setup' },
        // { icon: <PlanIcon />, title: 'Subscriptions Setup', description: 'Manage your subscription plans.', path: 'subscriptions', roles: ['super_admin', 'org_admin', 'merchant'] },
        // { icon: <RoomPreferencesIcon />, title: 'Booking Setup', description: 'Configure and manage bookings for your spaces or services.', path: 'booking-setup' },
        { icon: <LocalOfferIcon />, title: 'Configure Coupons', description: 'Create, manage coupons', path: 'coupon' },
        // { icon: <CalendarTodayIcon />, title: 'Appointments', description: 'Schedule and manage customer appointments.', path: 'appointments' },
    ];

    // const otherSettings = [
    //     { icon: <CalendarTodayIcon />, title: 'Appointments', description: 'Manage your appointments and schedules.', path: 'appointments' },
    //     { icon: <BookmarkIcon />, title: 'Booking/Order/Ticketing Status', description: 'Track the status of your bookings, orders, and tickets.', path: 'booking-order-status' },
    //     { icon: <HistoryIcon />, title: 'Transaction History', description: 'View your transaction history and details.', path: 'transaction-history' },
    //     { icon: <ContactsIcon />, title: 'Customer Record & Profile', description: 'Manage customer records and profiles.', path: 'customer-records' },
    //     { icon: <BillingIcon />, title: 'Billing & Plans', description: 'Easily Access and Update Your Plans and Billing Information.', path: 'billing' },
    //     { icon: <PaymentIcon />, title: 'Payment', description: 'Set up and manage payment methods and transactions.', path: 'payment' },
    // ];

    const handleCardClick = async (path) => {
        try {
            // Ensure valid token before navigation
            await authService.ensureValidToken();
            
            if (path === 'landing-page') {
                // For landing page, we need to include token and organizationId in the URL
                const token = await authService.getAccessToken();
                const organizationId = userInfo?.organization_id;
                
                if (!organizationId) {
                    console.error('No organization ID found');
                    return;
                }

                const landingPageUrl = new URL('https://pages.eventlah.com');
                landingPageUrl.searchParams.append('token', token);
                landingPageUrl.searchParams.append('organizationId', organizationId);
                
                window.location.href = landingPageUrl.toString();
            } else {
                // For other paths, just navigate normally
                navigate(`/settings/${path}`);
            }
        } catch (error) {
            console.error('Error in handleCardClick:', error);
        }
    };

    const SettingsSection = ({ title, subtitle, settings }) => {
        const [showAlert, setShowAlert] = useState(false);

        return (
            <Box sx={{ paddingTop: title === 'Product Settings' || title === 'Others' ? '32px' : 0 }}>
                <Typography variant="h4">
                    {title}
                </Typography>
                <Typography variant="h5" sx={{ paddingBottom: '22px' }}>
                    {subtitle}
                </Typography>
                {showAlert && (
                    <Alert severity="warning" onClose={() => setShowAlert(false)} sx={{ mb: 2 }}>
                        Some features are disabled based on your current role. Contact your administrator for access to additional features.
                    </Alert>
                )}
                <Grid container spacing={3}>
                    {settings.map((item, index) => {
                        const isDisabled = item.roles && Array.isArray(item.roles) && !item.roles.some(role => organizationRoles.includes(role));

                        return (
                            <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                                <Card
                                    onClick={() => {
                                        if (isDisabled) {
                                            setShowAlert(true);
                                        } else {
                                            handleCardClick(item.path);
                                        }
                                    }}
                                    sx={{
                                        opacity: isDisabled ? 0.5 : 1,
                                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                                    }}
                                >
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <Box sx={{ mr: 2, mt: 0.5, flexShrink: 0 }}>
                                                {React.cloneElement(item.icon, {
                                                    style: {
                                                        width: 24,
                                                        height: 24,
                                                        color: theme.palette.primary.contrastText
                                                    }
                                                })}
                                            </Box>
                                            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                                                    {item.title}
                                                </Typography>
                                                <Box sx={{ minHeight: '36px', display: 'flex', alignItems: 'flex-start' }}>
                                                    <DescriptionTypography>
                                                        {item.description}
                                                    </DescriptionTypography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
    };

    return (
        <Box sx={{ px: { xs: 2, lg: 3 } }}>
            <SettingsSection
                title="Business Settings"
                subtitle="Manage your business preferences and configurations"
                settings={businessSettings}
            />
            <SettingsSection
                title="Product Settings"
                subtitle="Control and configure your product settings"
                settings={productSettings}
            />
        </Box>
    );
};

export default SettingsPage;
