import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useKeycloak } from '../contexts/KeycloakContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchAdminOrganizations = createAsyncThunk(
    'adminOrganizations/fetchAdminOrganizations',
    async (params = { page: 1, limit: 10, status: '1', search: '' }, { getState }) => {
        try {
            const { keycloak } = useKeycloak();
            const token = keycloak.token;
            
            if (!token) {
                throw new Error('No token available');
            }

            const response = await axios.get(`${API_BASE_URL}/admin-organizations`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: params,
            });
            return response.data;
        } catch (error) {
            console.error('fetchAdminOrganizations error:', error.response?.data || error.message);
            return getState().adminOrganizations.error = error.response?.data || { message: error.message };
        }
    }
);

export const fetchSingleOrganization = createAsyncThunk(
    'adminOrganizations/fetchSingleOrganization',
    async (id, { getState }) => {
        try {
            const { keycloak } = useKeycloak();
            const token = keycloak.token;
            
            if (!token) {
                throw new Error('No token available');
            }

            const response = await axios.get(`${API_BASE_URL}/admin-organizations/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('fetchSingleOrganization error:', error.response?.data || error.message);
            return getState().adminOrganizations.error = error.response?.data || { message: error.message };
        }
    }
);

export const updateAdminOrganization = createAsyncThunk(
    'adminOrganizations/updateAdminOrganization',
    async ({ id, data }, { getState }) => {
        try {
            const { keycloak } = useKeycloak();
            const token = keycloak.token;
            
            if (!token) {
                throw new Error('No token available');
            }

            const response = await axios.put(`${API_BASE_URL}/admin-organizations?id=${id}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('updateAdminOrganization error:', error.response?.data || error.message);
            return getState().adminOrganizations.error = error.response?.data || { message: error.message };
        }
    }
);

export const deleteAdminOrganization = createAsyncThunk(
    'adminOrganizations/deleteAdminOrganization',
    async (id, { getState }) => {
        try {
            const { keycloak } = useKeycloak();
            const token = keycloak.token;
            
            if (!token) {
                throw new Error('No token available');
            }

            const response = await axios.delete(`${API_BASE_URL}/admin-organizations?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return id;
        } catch (error) {
            console.error('deleteAdminOrganization error:', error.response?.data || error.message);
            return getState().adminOrganizations.error = error.response?.data || { message: error.message };
        }
    }
);

const adminOrganizationSlice = createSlice({
    name: 'adminOrganizations',
    initialState: {
        organizations: [],
        selectedOrganization: null,
        pagination: {
            currentPage: 1,
            totalPages: 0,
            totalCount: 0,
            limit: 10
        },
        status: 'idle',
        error: null,
        currentStatus: '1',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdminOrganizations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAdminOrganizations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.organizations = action.payload.data.organizations;
                state.pagination = action.payload.data.pagination;
                state.currentStatus = action.meta.arg.status || '1';
                state.error = null;
            })
            .addCase(fetchAdminOrganizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || action.error.message;
            })
            .addCase(fetchSingleOrganization.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSingleOrganization.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedOrganization = action.payload;
            })
            .addCase(fetchSingleOrganization.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || action.error.message;
            })
            .addCase(updateAdminOrganization.fulfilled, (state, action) => {
                const updatedOrg = action.payload.data;
                const index = state.organizations.findIndex(org => org.id === updatedOrg.id);
                if (index !== -1) {
                    state.organizations[index] = updatedOrg;
                }
                if (state.selectedOrganization?.id === updatedOrg.id) {
                    state.selectedOrganization = updatedOrg;
                }
            })
            .addCase(deleteAdminOrganization.fulfilled, (state, action) => {
                state.organizations = state.organizations.filter(org => org.id !== action.payload);
                state.pagination.totalCount -= 1;
                if (state.selectedOrganization?.id === action.payload) {
                    state.selectedOrganization = null;
                }
            });
    },
});

export default adminOrganizationSlice.reducer;
