import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Switch,
    TextField,
    Button,
    Grid,
    Paper,
    MenuItem,
    Alert,
    CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '../../../../../../contexts/KeycloakContext';

const BizAppPayForm = ({ 
    header, 
    subHeader, 
    imgSrc, 
    imgAlt, 
    onSave, 
    onCancel, 
    initialEnabled = false,
    existingMethod = null 
}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { isAuthenticated, userInfo } = useKeycloak();
    const organizationId = userInfo?.organization_id;

    const [formData, setFormData] = useState({
        enabled: initialEnabled,
        title: existingMethod?.title || '',
        description: existingMethod?.description || '',
        apiKey: existingMethod?.api_key || '',
        categoryCode: existingMethod?.category_code || '',
        sandboxMode: existingMethod?.sandbox_mode === 'true' || false,
        baseUrl: existingMethod?.base_url || 'https://api.bizappay.com/api/v3'
    });

    useEffect(() => {
        if (existingMethod) {
            setFormData({
                enabled: initialEnabled,
                title: existingMethod.title || '',
                description: existingMethod.description || '',
                apiKey: existingMethod.api_key || '',
                categoryCode: existingMethod.category_code || '',
                sandboxMode: existingMethod.sandbox_mode === 'true' || false,
                baseUrl: existingMethod.base_url || 'https://api.bizappay.com/api/v3'
            });
        }
    }, [existingMethod, initialEnabled]);

    const handleChange = (field) => (event) => {
        const value = field === 'enabled' || field === 'sandboxMode' 
            ? event.target.checked 
            : event.target.value;
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async () => {
        if (!isAuthenticated || !organizationId) {
            setError('Authentication required');
            return;
        }

        try {
            setLoading(true);
            setError(null);

            await onSave({
                title: formData.title,
                description: formData.description,
                api_key: formData.apiKey,
                category_code: formData.categoryCode,
                sandbox_mode: formData.sandboxMode.toString(),
                base_url: formData.baseUrl
            }, formData.enabled);

        } catch (error) {
            console.error('Failed to save payment method:', error);
            setError(error.response?.data?.message || error.message || 'An error occurred while saving the payment method');
        } finally {
            setLoading(false);
        }
    };

    const isFormValid = () => {
        return formData.title &&
            formData.apiKey &&
            formData.categoryCode &&
            formData.baseUrl;
    };

    if (!isAuthenticated || !organizationId) {
        return (
            <Box sx={{ p: 2 }}>
                <Alert severity="error">
                    Please log in to manage payment methods
                </Alert>
            </Box>
        );
    }

    return (
        <Paper elevation={0} sx={{ bgcolor: '#f0f0f0', p: 3, maxWidth: 600 }}>
            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            <Box sx={{ display: 'flex', mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1, mr: 1 }}>
                    <Box component="img" src={imgSrc} alt={imgAlt} sx={{ width: '50px' }} />
                </Box>
                <Box>
                    <Typography variant="h6" gutterBottom>
                        {header}
                    </Typography>
                    <Typography variant="body2" gutterBottom color="text.secondary">
                        {subHeader}
                    </Typography>
                </Box>
            </Box>

            <Box component="form" sx={{ mt: 3 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Enable / Disable</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Switch
                            size="small"
                            checked={formData.enabled}
                            onChange={handleChange('enabled')}
                            disabled={loading}
                        />
                        <Typography variant="body2" component="span" sx={{ ml: 1 }}>
                            Enable this payment gateway
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Title*</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            value={formData.title}
                            onChange={handleChange('title')}
                            required
                            disabled={loading}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Description</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            size="small"
                            value={formData.description}
                            onChange={handleChange('description')}
                            disabled={loading}
                            InputProps={{
                                style: { padding: 0 }
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>API Key*</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            value={formData.apiKey}
                            onChange={handleChange('apiKey')}
                            required
                            disabled={loading}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Category Code*</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            value={formData.categoryCode}
                            onChange={handleChange('categoryCode')}
                            required
                            disabled={loading}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Base URL*</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            value={formData.baseUrl}
                            onChange={handleChange('baseUrl')}
                            required
                            disabled={loading}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Sandbox Mode</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Switch
                            size="small"
                            checked={formData.sandboxMode}
                            onChange={handleChange('sandboxMode')}
                            disabled={loading}
                        />
                        <Typography variant="body2" component="span" sx={{ ml: 1 }}>
                            Enable sandbox mode for testing
                        </Typography>
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={onCancel}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading || !isFormValid()}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default BizAppPayForm;
