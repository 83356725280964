import React, { useEffect, useState, useCallback } from "react";
import { Box, Button, Divider, Paper, Typography, Stepper, Step, StepLabel, CircularProgress, Tooltip, Link, IconButton } from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from "../context/FormContext";
import { fetchOrganizations, patchOrganization } from "../../redux/organizationSlice";
import { useDispatch, useSelector } from "react-redux";
import StoreSetupForm from "../shared/StoreSetupForm";
import {setCurrentStep, updateOnboardingRecord} from "../../redux/accountOnboardingSlice";
import keycloak from "../../keycloak";
import { fetchOrganizationDomain } from "../../redux/organizationDomainSlice";
import { useUserInfo } from '../../hooks/useUserInfo';
import { authService } from '../../services/auth.service';

const API_URL = process.env.REACT_APP_API_BASE_URL;
const IMAGE_URL = 'https://s3.ap-southeast-5.amazonaws.com/my.merchants/';

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];

const ThirdStep = () => {
    const [activeStep, setActiveStep] = useState(2);
    const dispatch = useDispatch();
    const { formData, updateFormData } = useFormContext();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formValid, setFormValid] = useState(false);
    const [storeData, setStoreData] = useState(null);
    const [logo, setLogo] = useState(null);
    const [logoError, setLogoError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const organization = useSelector(state => state.organizations.organization);
    const [subdomain, setSubdomain] = useState('');
    const { userInfo } = useUserInfo();

    const handleFormDataChange = useCallback((data) => {
        console.log('Form data changed:', data);
        setStoreData({
            ...data,
            id: userInfo?.organization_id,
            storeUrl: `${data.subdomain}.eventlah.com`,
        });
    }, [userInfo]);

    const handleCancel = () => {
        dispatch(setCurrentStep('second'));
        navigate('/second');
    }

    const handleSave = async () => {
        try {
            await authService.ensureValidToken();

            if (!storeData) {
                throw new Error('Store data is required');
            }

            console.log('Saving store data:', storeData);

            // Save the store data first with correct field names
            await dispatch(patchOrganization({
                id: userInfo?.organization_id,
                store_name: storeData.storeName,
                store_url: storeData.storeUrl, // API will add https:// if needed
                contact_number: storeData.contactNumber,
                email_address: storeData.emailAddress,
                store_description: storeData.storeDescription,
                operating_hours: storeData.operatingHours,
            })).unwrap();

            // Update onboarding status
            await dispatch(updateOnboardingRecord({
                email: userInfo?.email,
                onboardingData: {
                    current_step: 'fourth',
                    completed_steps: ['first', 'second', 'third']
                }
            })).unwrap();

            dispatch(setCurrentStep('fourth'));
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            navigate('/fourth');
        } catch (error) {
            console.error('Failed to update server:', error);
            setError("Failed to save store details. Please try again.");
        }
    }

    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsUploading(true);
        setLogoError(null);

        try {
            const token = await authService.ensureValidToken();

            // Step 1: Get presigned URL
            const createResponse = await axios.post(`${API_URL}/uploads?type=logo`, {
                filename: file.name,
                contentType: file.type,
                size: file.size,
                organizationId: userInfo?.organization_id
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (createResponse.data.code === 200 && createResponse.data.data) {
                const { uploadId, presignedUrl } = createResponse.data.data;

                // Step 2: Upload to S3
                await axios.put(presignedUrl, file, {
                    headers: {
                        'Content-Type': file.type
                    }
                });

                // Step 3: Confirm upload
                const confirmResponse = await axios.post(`${API_URL}/uploads?id=${uploadId}&confirm=true`, null, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (confirmResponse.data.code === 200 && confirmResponse.data.data) {
                    // Update organization with s3Key instead of filename
                    await dispatch(patchOrganization({
                        id: userInfo?.organization_id,
                        logo: confirmResponse.data.data.s3Key
                    })).unwrap();

                    setLogo(confirmResponse.data.data);
                }
            }
        } catch (error) {
            console.error('Failed to upload logo:', error);
            setLogoError('Failed to upload logo. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const orgData = await dispatch(fetchOrganizations()).unwrap();
                if (orgData.logo) {
                    setLogo({ filename: orgData.logo });
                }
                setError(null);
            } catch (err) {
                setError("Failed to fetch organization data. Please try again later.");
                console.error("Error fetching organization:", err);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch, userInfo]);

    useEffect(() => {
        if (!formData.storeLinkBase) {
            updateFormData('storeLinkBase', 'https://pages.eventlah.com/');
        }
    }, [formData.storeLinkBase, updateFormData]);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%', p: 3 }}>
            <Stepper activeStep={2} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper sx={{ p: 3, mt: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant='h4'>Store Setup</Typography>
                    <Typography variant='h5'>
                        Please provide your store details to begin setting up your online presence on Eventlah.
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4, gap: 2 }}>
                    <Box
                        sx={{
                            width: 100,
                            height: 100,
                            borderRadius: '50%',
                            border: '1px dashed grey',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            overflow: 'hidden',
                            backgroundColor: '#f5f5f5'
                        }}
                    >
                        {logo ? (
                            <img
                                src={`${IMAGE_URL}${logo.filename}`}
                                alt="Store logo"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <img 
                                    src="/cart-icon.png" 
                                    alt="Default store icon"
                                    style={{ width: '50px', height: '50px', opacity: 0.5 }}
                                />
                            </Box>
                        )}
                    </Box>
                    
                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                        <Typography variant="h6" gutterBottom>
                            Choose store logo
                        </Typography>
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="logo-upload"
                            onChange={handleLogoUpload}
                            disabled={isUploading}
                        />
                        <label htmlFor="logo-upload">
                            <Button
                                component="span"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#F4511E',
                                    '&:hover': {
                                        backgroundColor: '#F4511E',
                                        opacity: 0.9
                                    }
                                }}
                                disabled={isUploading}
                            >
                                Choose File
                            </Button>
                        </label>
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                            Recommended: Square JPG, PNG, or GIF, at least 300px x 300px.
                        </Typography>
                        <Typography variant="body2" color="error">
                            {logoError}
                        </Typography>
                        {isUploading && <CircularProgress size={24} sx={{ mt: 1 }} />}
                    </Box>
                </Box>

                <Divider sx={{ mb: 3 }}/>

                <StoreSetupForm 
                    onSaveSuccess={handleSave} 
                    onFormValidityChange={setFormValid}
                    onFormDataChange={handleFormDataChange}
                    showEditButton={false}
                />

                <Box sx={{ display: 'flex', gap: '12px', justifyContent: { xs: 'center', md: 'flex-end' }, mt: 5 }}>
                    <Button
                        onClick={() => navigate('/fourth')}
                        sx={{
                            textTransform: 'none',
                            color: 'orange',
                            border: 'none',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                border: 'none',
                            },
                        }}
                    >
                        Skip for now
                    </Button>
                    <Button variant="cancel" onClick={handleCancel}>Previous</Button>
                    <Tooltip title="Please complete the store setup form" placement="top">
                        <span>
                            <Button 
                                variant="save" 
                                onClick={handleSave}
                                disabled={!formValid}
                                sx={{
                                    textTransform: 'none',
                                    height: '34px',
                                    '&.Mui-disabled': {
                                        color: 'white'
                                    }
                                }}
                            >
                                Next
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Paper>
        </Box>
    );
};

export default ThirdStep;
