import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Paper,
    Button,
    Popover,
    Checkbox,
    FormControlLabel,
    Link,
    CircularProgress,
    Alert,
    Snackbar,
} from '@mui/material';
import { styled } from '@mui/system';
import { ChromePicker } from 'react-color';
import { CompanyLogo } from "../../../header/HeaderSvgImages";
import {
    fetchThemeComponents,
    updateThemeComponent,
    selectAllThemeComponents,
    selectThemeComponentsStatus,
    selectThemeComponentsError,
    selectUpdateStatus,
    selectUpdateError,
    resetUpdateStatus
} from '../../../../redux/themeComponentsSlice';
import { useKeycloak } from '../../../../contexts/KeycloakContext';
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';
import { fetchOrganizationPublicDetails } from "../../../../redux/organizationPublicDetailsSlice";
import {QRCodeSVG} from "qrcode.react";

const s3Client = new S3Client({
    region: "ap-southeast-5",
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_BITBUCKET_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_BITBUCKET_SECRET_ACCESS_KEY
    },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),
}));

const PreviewPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),
}));

const ColorBox = styled(Box)(({ bgcolor }) => ({
    width: '24px',
    height: '24px',
    backgroundColor: bgcolor,
    cursor: 'pointer',
    border: '1px solid #000',
    borderRadius: '50%',
    marginRight: '8px',
}));

const defaultFormData = {
    logo: <CompanyLogo/>,
    mainText: '',
    description: '',
    content: '',
    button1: '',
    footerText: '',
    button2: '',
    font: '',
    companyName: '',
    companyAddress: '',
    businessWebsite: '',
    showCompanyName: false,
    showCompanyAddress: false,
    showBusinessWebsite: false,
    colors: {
        body: {
            text: '#000000',
            buttonsAndLinks: '#FEE000',
            buttonLabelsAndOutlines: '#232323',
        },
        background: {
            background1: '#f5f5f5',
            background2: '#FEE000',
        },
        table: {
            tableBorderColor: '#000'
        },
        footer: {
            textAndIcons: '#232323',
            background: '#FFF',
        },
    },
};

/* TODO: Need to make sure data is saved correctly for table styles */

const ConfirmationEmail = () => {
    const dispatch = useDispatch();
    const { userInfo } = useKeycloak();
    const themeComponents = useSelector(selectAllThemeComponents);
    const fetchStatus = useSelector(selectThemeComponentsStatus);
    const fetchError = useSelector(selectThemeComponentsError);
    const updateStatus = useSelector(selectUpdateStatus);
    const updateError = useSelector(selectUpdateError);

    const [formData, setFormData] = useState(defaultFormData);
    const [uploading, setUploading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
    const [activeColorField, setActiveColorField] = useState(null);

    const organizationPublicDetails = useSelector((state) => state.organizationPublicDetails.details);

    // Fetch initial data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const organizationId = userInfo?.organization_id;
                if (!organizationId) {
                    throw new Error('Organization ID not found');
                }

                await dispatch(fetchOrganizationPublicDetails(organizationId));
                await dispatch(fetchThemeComponents({
                    page: 1,
                    limit: 10,
                    organization_id: organizationId
                }));
            } catch (error) {
                console.error('Failed to fetch initial data:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Failed to load data. Please refresh the page.');
                setSnackbarOpen(true);
            }
        };

        if (fetchStatus === 'idle' && userInfo) {
            fetchData();
        }
    }, [dispatch, fetchStatus, userInfo]);

    // Update form with organization details
    useEffect(() => {
        if (organizationPublicDetails?.length > 0) {
            const details = organizationPublicDetails[0]?.data;
            setFormData(prevData => ({
                ...prevData,
                companyName: details?.business_name || '',
                companyAddress: [
                    details?.customer_support_address_line_1,
                    details?.customer_support_address_line_2,
                    details?.postal_code,
                    details?.city,
                    details?.state
                ].filter(Boolean).join(', '),
                businessWebsite: details?.business_website || '',
            }));
        }
    }, [organizationPublicDetails]);

    // Update form with theme component data
    useEffect(() => {
        if (themeComponents && themeComponents.length > 0) {
            const confirmComponent = themeComponents.find(component => component.name === 'confirm');
            if (confirmComponent) {
                const newFormData = {
                    ...defaultFormData,
                    mainText: confirmComponent.main_text || '',
                    description: confirmComponent.description || '',
                    font: confirmComponent.typography || '',
                    showCompanyName: confirmComponent.show_company_name || false,
                    showCompanyAddress: confirmComponent.show_company_address || false,
                    showBusinessWebsite: confirmComponent.show_business_website || false,
                    colors: {
                        body: {
                            text: confirmComponent.body_text_color || '#333333',
                            buttonsAndLinks: confirmComponent.button_color || '#ffeb3b',
                            buttonLabelsAndOutlines: confirmComponent.button_label_color || '#434040',
                        },
                        background: {
                            background1: confirmComponent.background_color_1 || '#FFFFFF',
                            background2: confirmComponent.background_color_2 || '#F5F5F5',
                        },
                        table: {
                            tableBorderColor: confirmComponent.table_border_color || '#000',
                        },
                        footer: {
                            textAndIcons: confirmComponent.footer_text_color || '#666666',
                            background: confirmComponent.footer_background_color || '#F9F9F9',
                        },
                    },
                    content: confirmComponent.other_text_1 || '',
                    button1: confirmComponent.other_text_2 || '',
                    footerText: confirmComponent.other_text_3 || '',
                    button2: confirmComponent.other_text_4 || '',
                    logo: confirmComponent.logo || <CompanyLogo/>,
                };

                setFormData(newFormData);
            }
        }
    }, [themeComponents]);

    // Monitor update status
    useEffect(() => {
        if (updateStatus === 'succeeded') {
            setSnackbarSeverity('success');
            setSnackbarMessage('Changes saved successfully!');
            setSnackbarOpen(true);
            dispatch(resetUpdateStatus());
        } else if (updateStatus === 'failed') {
            setSnackbarSeverity('error');
            setSnackbarMessage(updateError || 'Failed to save changes.');
            setSnackbarOpen(true);
            dispatch(resetUpdateStatus());
        }
    }, [updateStatus, updateError, dispatch]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const handleColorChange = (color) => {
        if (!activeColorField) return;

        setFormData(prevData => ({
            ...prevData,
            colors: {
                ...prevData.colors,
                [activeColorField.group]: {
                    ...prevData.colors[activeColorField.group],
                    [activeColorField.field]: color.hex,
                },
            },
        }));
    };

    const handleColorBoxClick = (event, group, field) => {
        setColorPickerAnchor(event.currentTarget);
        setActiveColorField({ group, field });
    };

    const handleSave = async () => {
        const organizationId = userInfo?.organization_id;
        if (!organizationId) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Organization ID not found. Please try logging in again.');
            setSnackbarOpen(true);
            return;
        }

        const confirmComponent = themeComponents.find(component => component.name === 'confirm');
        if (!confirmComponent) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Confirmation Email component not found.');
            setSnackbarOpen(true);
            return;
        }

        try {
            const themeComponentData = {
                organization_id: organizationId,
                name: 'confirm',
                main_text: formData.mainText,
                description: formData.description,
                typography: formData.font,
                show_company_name: formData.showCompanyName,
                show_company_address: formData.showCompanyAddress,
                show_business_website: formData.showBusinessWebsite,
                body_text_color: formData.colors.body.text,
                button_color: formData.colors.body.buttonsAndLinks,
                button_label_color: formData.colors.body.buttonLabelsAndOutlines,
                background_color_1: formData.colors.background.background1,
                background_color_2: formData.colors.background.background2,
                footer_text_color: formData.colors.footer.textAndIcons,
                footer_background_color: formData.colors.footer.background,
                table_border_color: formData.colors.table.tableBorderColor,
                other_text_1: formData.content,
                other_text_2: formData.button1,
                other_text_3: formData.footerText,
                other_text_4: formData.button2,
                logo: typeof formData.logo === 'string' ? formData.logo : null,
            };

            console.log('Sending update with data:', themeComponentData);

            const result = await dispatch(updateThemeComponent({
                id: confirmComponent.id,
                themeComponentData
            })).unwrap();

            if (result.code === 200 || result.code === 201) {
                setSnackbarSeverity('success');
                setSnackbarMessage('Theme updated successfully!');
                setSnackbarOpen(true);

                // Refresh the data
                await dispatch(fetchThemeComponents({
                    page: 1,
                    limit: 10,
                    organization_id: organizationId
                }));
            } else {
                throw new Error(result.description || 'Update failed');
            }
        } catch (error) {
            console.error('Save error:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage(error.message || 'Failed to update theme component.');
            setSnackbarOpen(true);
        }
    };

    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setUploading(true);
        try {
            const timestamp = Date.now();
            const uniqueId = uuidv4().slice(0, 6);
            const fileNameParts = file.name.split('.');
            const fileExtension = fileNameParts.pop();
            const fileName = fileNameParts.join('.');
            const uniqueFileName = `${fileName}_${timestamp}_${uniqueId}.${fileExtension}`;
            const organizationId = userInfo?.organization_id || '1';
            const key = `${organizationId}/${uniqueFileName}`;

            const params = {
                Bucket: "my.merchants",
                Key: key,
                Body: file,
                ContentType: file.type,
            };

            await s3Client.send(new PutObjectCommand(params));
            const fileUrl = `https://s3.ap-southeast-5.amazonaws.com/my.merchants/${encodeURIComponent(key)}`;

            const confirmComponent = themeComponents.find(component => component.name === 'confirm');
            if (confirmComponent) {
                await dispatch(updateThemeComponent({
                    id: confirmComponent.id,
                    themeComponentData: { logo: fileUrl }
                }));

                setFormData(prevData => ({
                    ...prevData,
                    logo: fileUrl,
                }));

                setSnackbarSeverity('success');
                setSnackbarMessage('Logo uploaded successfully!');
                setSnackbarOpen(true);
            }
        } catch (err) {
            console.error("Error uploading file:", err);
            setSnackbarSeverity('error');
            setSnackbarMessage('Failed to upload logo.');
            setSnackbarOpen(true);
        } finally {
            setUploading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const renderColorOption = (group, field, label, allowNone = false) => {
        const color = formData.colors[group][field];
        return (
            <Box display="flex" alignItems="center" mb={1}>
                <ColorBox
                    bgcolor={color || 'transparent'}
                    onClick={(e) => handleColorBoxClick(e, group, field)}
                />
                <Typography variant="body2">
                    {label}
                    {color ? ` ${color}` : ' None'}
                </Typography>
            </Box>
        );
    };

    const fontOptions = [
        'Arial', 'Helvetica', 'Verdana', 'Trebuchet MS', 'Gill Sans',
        'Noto Sans', 'Avantgarde', 'Optima', 'Arial Narrow', 'sans-serif',
        'Times', 'Times New Roman', 'Didot', 'Georgia', 'Palatino',
        'Bookman', 'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 'Impact'
    ];

    if (fetchStatus === 'loading') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (fetchStatus === 'failed') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">Error: {fetchError}</Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                minHeight: '100vh',
            }}
        >
            {/* Form Section */}
            <StyledPaper
                elevation={3}
                sx={{
                    flex: { xs: 1, md: 0.8 },
                    overflowY: 'auto',
                    borderRadius: '12px',
                    p: { xs: 2, md: 3 },
                }}
            >
                {/* Logo Upload Section */}
                <Typography sx={{ fontSize: '16px', fontWeight: '700' }} gutterBottom>
                    Logo
                </Typography>
                <Typography variant="caption" gutterBottom>
                    Recommended image size: 250px x 250px
                </Typography>
                <br />
                <label htmlFor="raised-button-file">
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleLogoUpload}
                    />
                    <Button
                        variant="save"
                        component="span"
                        disabled={uploading || updateStatus === 'loading'}
                    >
                        {uploading ? <CircularProgress size={24} /> : 'Upload Logo'}
                    </Button>
                </label>

                {/* Main Text Section */}
                <Typography variant="h6" gutterBottom sx={{ fontSize: '16px', fontWeight: '700', mt: 4 }}>
                    Main Text
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="mainText"
                    value={formData.mainText}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                />

                {/* Description Section */}
                <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }}>
                    Description
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                />

                {/* Typography Section */}
                <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }}>
                    Typography
                </Typography>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel>Font</InputLabel>
                    <Select
                        value={formData.font}
                        onChange={handleInputChange}
                        label="Font"
                        name="font"
                    >
                        {fontOptions.map((font) => (
                            <MenuItem key={font} value={font}>
                                {font}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Company Information Section */}
                <Typography sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }} gutterBottom>
                    Company Information
                    <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
                        If you want to change your business details,{' '}
                        <Link
                            href="/settings/profile-settings#public-details"
                            underline="always"
                            sx={{ color: 'black', '&:hover': { color: 'black' }, fontWeight: 'bold' }}
                        >
                            click here
                        </Link>
                    </Alert>
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showCompanyName}
                                onChange={handleCheckboxChange}
                                name="showCompanyName"
                            />
                        }
                        label="Show Company Name"
                        sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showCompanyAddress}
                                onChange={handleCheckboxChange}
                                name="showCompanyAddress"
                            />
                        }
                        label="Show Company Address"
                        sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showBusinessWebsite}
                                onChange={handleCheckboxChange}
                                name="showBusinessWebsite"
                            />
                        }
                        label="Show Business Website"
                        sx={{ marginLeft: 0 }}
                    />
                </Box>

                {/* Colors Section */}
                <Typography sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }} gutterBottom>
                    Colors
                </Typography>
                <Box mb={2}>
                    <Typography variant="subtitle1">Body</Typography>
                    {renderColorOption('body', 'text', 'Text')}
                    {renderColorOption('body', 'buttonsAndLinks', 'Buttons and links')}
                    {renderColorOption('body', 'buttonLabelsAndOutlines', 'Button labels and outlines')}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">Background</Typography>
                    {renderColorOption('background', 'background1', 'Background 1')}
                    {renderColorOption('background', 'background2', 'Background 2')}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">Content</Typography>
                    {/*{renderColorOption('background', 'background1', 'Background Color')}*/}
                    {renderColorOption('table', 'tableBorderColor', 'Seperator Color')}
                    {/*{renderColorOption('background', 'background2', 'Header Background Color')}*/}
                    {/*{renderColorOption('background', 'background2', 'Header Text Color')}*/}
                    {/*{renderColorOption('background', 'background2', 'Row Background  Color')}*/}
                    {/*{renderColorOption('background', 'background2', 'Row Background Color')}*/}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">Footer</Typography>
                    {renderColorOption('footer', 'textAndIcons', 'Text and icons', true)}
                    {renderColorOption('footer', 'background', 'Background', true)}
                </Box>

                {/* Other Texts Section */}
                <Typography sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }} gutterBottom>
                    Other Texts
                </Typography>
                {[
                    { name: 'footerText', label: 'Footer Text' },
                    { name: 'button2', label: 'Button 2' },
                ].map(({ name, label }) => (
                    <TextField
                        key={name}
                        fullWidth
                        variant="outlined"
                        name={name}
                        label={label}
                        value={formData[name]}
                        onChange={handleInputChange}
                        sx={{ mb: 2 }}
                    />
                ))}

                {/* Save Button */}
                <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={updateStatus === 'loading'}
                    sx={{ mt: 2 }}
                >
                    {updateStatus === 'loading' ? <CircularProgress size={24} /> : 'Save Changes'}
                </Button>
            </StyledPaper>

            {/* Preview Section */}
            <PreviewPaper
                sx={{
                    flex: { xs: 1, md: 1 },
                    borderRadius: '12px',
                    mt: { xs: 3, md: 0 },
                    p: { xs: 2, md: 3 },
                    bgcolor: formData.colors.background.background1,
                    boxShadow: 'none',
                    backgroundColor: 'transparent'
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        mb: 6,
                        mt: 3,
                        textAlign: 'left',
                        borderRadius: '12px',
                        fontFamily: formData.font,
                        bgcolor: formData.colors.background.background2,

                    }}
                >
                    <div className="container" style={{color: formData.colors.body.text}}>
                        <h1>{formData.mainText}</h1>
                        <p>{formData.description}</p>
                        <p>Dear [Customer Name],</p>
                        <p>{formData.content}</p>
                        <p>We are pleased to confirm that we have received your order #[OrderNumber]. Here are the
                            details of your order:</p>

                        <table style={{width: '100%', borderCollapse: 'collapse', marginBottom: '20px'}}>
                            <thead>
                            <tr style={{backgroundColor: formData.colors.background.background2, borderBottom: `1px solid ${formData.colors.table.tableBorderColor}`}}>
                                <th style={{padding: '10px'}}>Item</th>
                                <th style={{padding: '10px'}}>Variant</th>
                                <th style={{padding: '10px'}}>Quantity</th>
                                <th style={{padding: '10px'}}>Price</th>
                                <th style={{padding: '10px'}}>QR Code</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className="type-header">
                                <th colSpan="5" style={{
                                    padding: '10px',
                                    backgroundColor: formData.colors.background.background2
                                }}>Tickets
                                </th>
                            </tr>
                            <tr style={{borderBottom: `1px solid ${formData.colors.table.tableBorderColor}`}}>
                                <td style={{padding: '10px'}}>Sample Ticket</td>
                                <td style={{padding: '10px'}}>-</td>
                                <td style={{padding: '10px'}}>1</td>
                                <td style={{padding: '10px'}}>RM 50.00</td>
                                <td style={{padding: '10px'}}>
                                    <QRCodeSVG value="https://merchants.eventlah.com" size={150} />
                                </td>
                            </tr>
                            <tr className="type-header">
                                <th colSpan="5" style={{
                                    padding: '10px',
                                    backgroundColor: formData.colors.background.background2
                                }}>Products
                                </th>
                            </tr>
                            <tr style={{borderBottom: `1px solid ${formData.colors.table.tableBorderColor}`}}>
                                <td style={{padding: '10px'}}>Sample Product</td>
                                <td style={{padding: '10px'}}>-</td>
                                <td style={{padding: '10px'}}>1</td>
                                <td style={{padding: '10px'}}>RM 100.00</td>
                                <td style={{padding: '10px'}}>-</td>
                            </tr>
                            <tr>
                                <td colSpan="4" style={{textAlign: 'right', padding: '10px'}}><strong>Total:</strong>
                                </td>
                                <td style={{padding: '10px'}}><strong>RM 150.00</strong></td>
                            </tr>
                            </tbody>
                        </table>

                        <p><strong>Shipping Address:</strong><br/>[Shipping Address Placeholder]</p>

                        {/*Disable track orders*/}
                        {/*<Button*/}
                        {/*    variant="contained"*/}
                        {/*    sx={{*/}
                        {/*        mt: 2,*/}
                        {/*        bgcolor: formData.colors.body.buttonsAndLinks,*/}
                        {/*        color: formData.colors.body.buttonLabelsAndOutlines,*/}
                        {/*        '&:hover': {*/}
                        {/*            bgcolor: formData.colors.body.buttonsAndLinks,*/}
                        {/*            opacity: 0.9,*/}
                        {/*        },*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {formData.button1}*/}
                        {/*</Button>*/}

                        <Box sx={{
                            bgcolor: formData.colors.footer.background,
                            color: formData.colors.footer.textAndIcons,
                            p: 2,
                            mt: 2,
                            textAlign: 'center'
                        }}>
                            <Typography variant="body1" sx={{mb: 1}}>
                                {formData.footerText}
                            </Typography>
                            <Link
                                href="#"
                                sx={{
                                    color: formData.colors.footer.textAndIcons,
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                            >
                                {formData.button2}
                            </Link>
                        </Box>
                    </div>
                </Box>
            </PreviewPaper>

            {/* Color Picker Popover */}
            <Popover
                open={Boolean(colorPickerAnchor)}
                anchorEl={colorPickerAnchor}
                onClose={() => {
                    setColorPickerAnchor(null);
                    setActiveColorField(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ChromePicker
                    color={activeColorField ? formData.colors[activeColorField.group][activeColorField.field] : '#fff'}
                    onChange={handleColorChange}
                />
            </Popover>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{width: '100%'}}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ConfirmationEmail;