import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { authService } from '../../services/auth.service';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    IconButton,
    Divider,
    Snackbar,
    Alert,
    Skeleton,
    Tabs,
    Tab,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { ViewIcon, EditIcon, DeleteIcon } from "../../components/shared/IconButtons";
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteConfirmationDialog from '../../components/shared/DeleteConfirmationDialog';
import dayjs from 'dayjs';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const statusMap = {
    1: 'Pending',
    2: 'Verified',
    3: 'Rejected'
};

const AdminMerchantList = () => {
    const navigate = useNavigate();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [pagination, setPagination] = useState({});
    const [status, setStatus] = useState('idle');
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [currentTab, setCurrentTab] = useState(0);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [organizationToDelete, setOrganizationToDelete] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const tabStatus = ['1', '2', '3'];

    const fetchOrganizations = async (params) => {
        try {
            setStatus('loading');
            const token = await authService.ensureValidToken();
            
            if (!token) {
                throw new Error('No token available');
            }

            const response = await axios.get(`${API_BASE_URL}/admin-organizations`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: {
                    page: paginationModel.page + 1,
                    limit: paginationModel.pageSize,
                    status: tabStatus[currentTab],
                    search: searchQuery,
                    ...params
                },
            });

            setOrganizations(response.data.data);
            setPagination(response.data.pagination);
            setStatus('succeeded');
            setError(null);
        } catch (error) {
            console.error('Error fetching organizations:', error);
            setStatus('failed');
            setError(error.response?.data || { message: error.message });
            setSnackbar({
                open: true,
                message: error.response?.data?.description || 'Failed to fetch organizations',
                severity: 'error'
            });
        }
    };

    const handleDeleteOrganization = async (id) => {
        try {
            setStatus('loading');
            const token = await authService.ensureValidToken();
            
            if (!token) {
                throw new Error('No token available');
            }

            await axios.delete(`${API_BASE_URL}/admin-organizations?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            setOrganizations(organizations.filter(org => org.id !== id));
            setStatus('succeeded');
            setError(null);
            setSnackbar({
                open: true,
                message: 'Organization deleted successfully',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error deleting organization:', error);
            setStatus('failed');
            setError(error.response?.data || { message: error.message });
            setSnackbar({
                open: true,
                message: error.response?.data?.description || 'Failed to delete organization',
                severity: 'error'
            });
        }
    };

    useEffect(() => {
        if (isInitialLoad) {
            fetchOrganizations();
            setIsInitialLoad(false);
        }
    }, [isInitialLoad]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            fetchOrganizations();
        }, 500);

        return () => clearTimeout(debounceTimer);
    }, [paginationModel.page, paginationModel.pageSize, currentTab, searchQuery]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handleDeleteClick = (organization) => {
        setOrganizationToDelete(organization);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        if (organizationToDelete) {
            handleDeleteOrganization(organizationToDelete.id);
            setDeleteDialogOpen(false);
            setOrganizationToDelete(null);
        }
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setOrganizationToDelete(null);
    };

    const renderSkeletons = () => (
        <Box sx={{ padding: '0 32px 24px 32px' }}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius: '4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const columns = [
        {
            field: 'name',
            headerName: 'Organization',
            flex: 1,
            minWidth: 300,
            renderCell: (params) => (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%'
                }}>
                    <Typography variant="body2">{params.row.name || 'N/A'}</Typography>
                    <Typography variant="caption" color="textSecondary" noWrap>
                        {params.row.registration_number || 'No Registration Number'}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'address',
            headerName: 'Address',
            flex: 1,
            minWidth: 300,
            renderCell: (params) => (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%'
                }}>
                    <Typography variant="body2" noWrap>
                        {params.row.company_address_1}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" noWrap>
                        {`${params.row.company_city}, ${params.row.company_state}, ${params.row.company_postcode}`}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'created_at',
            headerName: 'Created',
            flex: 0.7,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                }}>
                    <Typography variant="body2">
                        {dayjs(params.value).format('DD/MM/YYYY')}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 120,
            renderCell: (params) => (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                }}>
                    <Typography variant="body2" sx={{
                        color: params.value === 2 ? 'success.main' : 
                               params.value === 3 ? 'error.main' : 
                               'warning.main'
                    }}>
                        {statusMap[params.value] || 'Unknown'}
                    </Typography>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%'
                }}>
                    <IconButton
                        size="small"
                        onClick={() => navigate(`/admin/merchants/${params.row.id}/view`)}
                    >
                        <ViewIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => navigate(`/admin/merchants/${params.row.id}/edit`)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => handleDeleteClick(params.row)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )
        }
    ];

    return (
        <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Organizations</Typography>
                <Button
                    variant="contained"
                    startIcon={<SettingsIcon />}
                    onClick={() => navigate('/admin/settings')}
                >
                    Settings
                </Button>
            </Box>
            
            <Divider />
            
            <Box sx={{ p: 3 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search organizations..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    sx={{ mb: 2 }}
                />
                
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    sx={{ mb: 2 }}
                >
                    <Tab label="Pending" />
                    <Tab label="Verified" />
                    <Tab label="Rejected" />
                </Tabs>
            </Box>

            {status === 'loading' ? (
                renderSkeletons()
            ) : (
                <DataGrid
                    rows={organizations}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    rowCount={pagination.total || 0}
                    paginationMode="server"
                    pageSizeOptions={[10, 25, 50]}
                    disableRowSelectionOnClick
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-cell': {
                            borderBottom: '1px solid #E5E7EB',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#F9FAFB',
                            borderBottom: '1px solid #E5E7EB',
                        },
                        '& .MuiDataGrid-row:hover': {
                            backgroundColor: '#F9FAFB',
                        }
                    }}
                />
            )}

            <DeleteConfirmationDialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                title="Delete Organization"
                content="Are you sure you want to delete this organization? This action cannot be undone."
            />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default AdminMerchantList;
