import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    CircularProgress
} from '@mui/material';
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { authService } from '../../services/auth.service';
import axios from '../../utils/axios';

// Card input styles
const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};

// Payment form component
const PaymentForm = ({ onSuccess, onCancel }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setProcessing(true);

        if (!stripe || !elements) {
            setProcessing(false);
            return;
        }

        try {
            const token = await authService.ensureValidToken();
            // Get setup intent
            const { data: { clientSecret } } = await axios.post('/stripe/setup-intent', {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // Confirm card setup
            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        email: (await authService.getUserInfo()).email
                    }
                }
            });

            if (result.error) {
                setError(result.error.message);
                setProcessing(false);
            } else {
                onSuccess(result.setupIntent.payment_method);
            }
        } catch (error) {
            setError(error.message);
            setProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
                <CardElement options={cardStyle} />
            </Box>
            {error && (
                <Typography color="error" sx={{ mb: 2 }}>
                    {error}
                </Typography>
            )}
            <DialogActions>
                <Button onClick={onCancel} disabled={processing}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={!stripe || processing}
                    startIcon={processing ? <CircularProgress size={20} /> : null}
                >
                    Add Card
                </Button>
            </DialogActions>
        </form>
    );
};

// Add Card Dialog Component
const AddCardDialog = ({ open, onClose, onSuccess }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add Payment Method</DialogTitle>
            <DialogContent>
                <PaymentForm
                    onSuccess={(paymentMethod) => {
                        onSuccess(paymentMethod);
                        onClose();
                    }}
                    onCancel={onClose}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AddCardDialog;
