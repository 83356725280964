import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { Box, Paper, Typography, Stepper, Step, StepLabel, Alert, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { setCurrentStep, updateOnboardingRecord } from "../../redux/accountOnboardingSlice";
import AddTicketingForm from '../shared/ticketing/AddTicketingForm';
import { authService } from '../../services/auth.service';

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];

const FifthStep = () => {
    const [activeStep, setActiveStep] = useState(4);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const handleEventCreated = async () => {
        try {
            const userInfo = await authService.getUserInfo();
            const result = await dispatch(updateOnboardingRecord({
                email: userInfo.email,
                onboardingData: {
                    current_step: 'sixth',
                    completed_steps: ['first', 'second', 'third', 'fourth', 'fifth']
                }
            })).unwrap();
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            dispatch(setCurrentStep('sixth'));
            navigate('/sixth');
        } catch (error) {
            console.error('Failed to update onboarding status:', error);
            setError('Failed to update onboarding status. Please try again.');
        }
    };

    const handlePrevious = async () => {
        try {
            const userInfo = await authService.getUserInfo();
            await dispatch(updateOnboardingRecord({
                email: userInfo.email,
                onboardingData: {
                    current_step: 'fourth',
                    completed_steps: ['first', 'second', 'third']
                }
            })).unwrap();
            dispatch(setCurrentStep('fourth'));
            navigate('/fourth');
        } catch (error) {
            console.error('Failed to update onboarding status:', error);
            setError('Failed to update onboarding status. Please try again.');
        }
    };

    const handleSkip = async () => {
        try {
            const userInfo = await authService.getUserInfo();
            await dispatch(updateOnboardingRecord({
                email: userInfo.email,
                onboardingData: {
                    current_step: 'sixth',
                    completed_steps: ['first', 'second', 'third', 'fourth', 'fifth']
                }
            })).unwrap();
            dispatch(setCurrentStep('sixth'));
            navigate('/sixth');
        } catch (error) {
            console.error('Failed to update onboarding status:', error);
            setError('Failed to update onboarding status. Please try again.');
        }
    };

    return (
        <>
            <Stepper activeStep={4} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, margin: '32px', borderRadius: '12px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', mb: 3 }}>
                    <Typography variant='h4'>
                        Event Setup
                    </Typography>
                    <Typography variant='h5'>
                        Create your first event to start selling tickets.
                    </Typography>
                </Box>

                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                <AddTicketingForm onEventCreated={handleEventCreated} />

                <Box sx={{ mt: 3 }}>
                    <Alert severity="info" sx={{ fontSize: '0.875rem', '& .MuiAlert-message': { width: '100%' } }}>
                        Please go to Configure Ticketing to finish all event details.
                    </Alert>
                </Box>

                <Box sx={{ display: 'flex', gap: '12px', justifyContent: 'flex-end', mt: 5 }}>
                    <Button
                        onClick={handleSkip}
                        sx={{
                            textTransform: 'none',
                            color: 'orange',
                            border: 'none',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                border: 'none',
                            },
                        }}
                    >
                        Skip for now
                    </Button>
                    <Button 
                        variant="cancel" 
                        onClick={handlePrevious}
                    >
                        Previous
                    </Button>
                    <Button 
                        variant="save"
                        onClick={handleEventCreated}
                        sx={{
                            textTransform: 'none',
                            height: '34px',
                            '&.Mui-disabled': {
                                color: 'white'
                            }
                        }}
                    >
                        Save & Next
                    </Button>
                </Box>
            </Paper>
        </>
    );
};

export default FifthStep;