import { Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/modals/ScrollToTop';
import CenteredLoading from './components/CenteredLoading';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedLayout from './components/layouts/ProtectedLayout';
import PublicLayout from './components/layouts/PublicLayout';
import SimpleLayout from './components/layouts/SimpleLayout';
import HeaderOnlyLayout from './components/layouts/HeaderOnlyLayout';

import MainDashboard from './components/dashboard/MainDashboard';
import PageBuilder from './components/PageBuilder';

import ServiceTable from './components/services/ServiceTable';
import ServiceForm from './components/services/ServiceForm';
import SpacesTable from './components/spaces/SpacesTable';
import SpacesForm from './components/spaces/SpacesForm';
import MerchantOnboarding from './components/merchant/MerchantOnboarding';
import MerchantSubscriptionPlans from './components/merchant/MerchantSubscriptionPlans/MerchantSubscriptionPlans';
import ProductPackage from './components/shared/ProductPackage';

import SettingsPage from './components/settings/Settings';
import SettingsLayout from './components/settings/SettingsLayout';
import ProfileSettings from './components/settings/business-settings/ProfileSettings';
import EditProfileSettings from './components/settings/business-settings/ProfileSettingsContents/EditProfileSettings';
import EmailNotifications from './components/settings/business-settings/EmailNotifications';
import LandingPage from './components/settings/business-settings/LandingPage';
import Branding from './components/settings/business-settings/Branding';
import ComplianceDocuments from './components/settings/business-settings/ComplianceDocuments';
import Subscriptions from './components/settings/business-settings/Subscriptions';
import CreateSubscription from './components/settings/business-settings/SubscriptionContents/CreateSubscription';
import SubscriptionDetails from './components/settings/business-settings/SubscriptionContents/SubscriptionDetails';
import TeamSecurity from './components/settings/business-settings/TeamSecurity';
import FreelanceAgent from './components/settings/business-settings/FreelanceAgent';
import AccountStatus from './components/settings/business-settings/AccountStatus';
import TaxDetails from './components/settings/business-settings/TaxDetails';
import CustomDomain from './components/settings/business-settings/CustomDomain';
import StoreSetup from './components/settings/business-settings/StoreSetup';
import MediaGallery from './components/settings/business-settings/MediaGallery';
import AgentSetup from './components/settings/business-settings/AgentSetup/AgentSetup';
import Uploads from './components/settings/business-settings/Uploads';
import DomainSetup from './components/settings/business-settings/DomainSetup/DomainSetup';
import DomainVerification from './components/settings/business-settings/DomainSetup/DomainVerification';

import ProductCatalog from './components/settings/product-settings/ProductCatalog/ProductCatalog';
import ProductDetails from './components/settings/product-settings/ProductCatalog/ProductDetails';
import BookingSetupList from './components/settings/product-settings/BookingSetup/BookingSetupList';
import BookingSetup from './components/settings/product-settings/BookingSetup/add-new-booking/BookingSetup';
import AddProduct from './components/settings/product-settings/ProductCatalog/AddProduct/AddProduct';
import Coupons from './components/settings/product-settings/Coupon/Coupon';
import ShippingRates from './components/settings/product-settings/ShippingRate/ShippingRate';
import TaxRates from './components/settings/product-settings/TaxRate/TaxRate';
import PricingTable from './components/settings/product-settings/PricingTable/PricingTable';

import Appointments from './components/settings/others/Appointments/Appointments';
import AddAppointments from './components/settings/others/Appointments/AddAppointments';
import OrdersList from './components/overview/OrdersOverview/OrdersList';
import TransactionHistory from './components/settings/others/TransactionHistory';
import CustomerRecords from './components/settings/others/CustomerRecords';
import Billing from './components/settings/others/Billing';
import Payment from './components/settings/others/Payment/Payment';

import Admin from './components/admin/Admin';
import AdminDashboard from './components/admin/AdminDashboard/AdminDashboard';
import AdminMerchantList from './components/admin/AdminMerchantList';
import AdminSettings from './components/admin/AdminSettings';
import CompanyName from './components/admin/AdminSettingsContent/CompanyName';
import AdminEmail from './components/admin/AdminSettingsContent/Email';
import AllTransactions from './components/admin/AdminSettingsContent/AllTransaction';
import TypeOfPaymentProcessor from './components/admin/AdminSettingsContent/TypeOfPaymentProcessor';
import PublicUrlGenerated from './components/admin/AdminSettingsContent/PublicUrlGenerated';
import AdminBusinessDetails from './components/admin/AdminSettingsContent/AdminBusinessDetails';
import ListOfProductsCreated from './components/admin/AdminSettingsContent/ListOfProductsCreated';
import AdminComplianceDocuments from './components/admin/AdminSettingsContent/AdminComplianceDocuments';
import AdminPublicDetails from './components/admin/AdminSettingsContent/AdminPublicDetails';
import AdminSecuritySettings from './components/admin/AdminSettingsContent/AdminSecuritySettings';
import RecentActivity from './components/admin/AdminSettingsContent/RecentActivity';
import AdminAccountStatus from './components/admin/AdminSettingsContent/AdminAccountStatus';
import SubscriptionList from './components/admin/AdminSubscriptions';
import AdminSubscriptionFeatures from './components/admin/AdminSubscriptionFeatures';
import AdminOnboarding from './components/admin/AdminOnboarding';
import AdminAgreementPage from './components/admin/AdminAgreementPage';
import AdminEmailManagement from './components/admin/AdminEmailManagement/AdminEmailManagement';
import AddEmail from './components/admin/AdminEmailManagement/AddEmail';
import AdminSubscriptionsSettings from './components/admin/AdminSubscriptionsSettings/AdminSubscriptionsSettings';
import AdminSubscriptionPlans from './components/admin/AdminSubscriptionPlans/AdminSubscriptionPlans';
import CollaborationPlans from './components/admin/AdminSubscriptionPlans/CollaborationPlans';
import FeatureManagement from './components/admin/AdminSubscriptionsSettings/FeatureManagement';
import AdminPaymentSettings from './components/admin/AdminPaymentSettings/AdminPaymentSettings';
import AdminNotificationSettings from './components/admin/AdminNotificationSettings/AdminNotificationSettings';
import RevenueOverview from './components/admin/RevenueOverview/RevenueOverview';
import AdminTicketingTransactions from './components/admin/AdminTicketingTransactions/AdminTicketingTransactions';
import MerchantRevenue from './components/MerchantRevenue';
import AdminTierUsage from './components/admin/AdminTierUsage/AdminTierUsage';

// import AgentDashboard from './components/agent/AgentDashboard/AgentDashboard';
// import AgentOnboarding from './components/agent/AgentOnboarding/AgentOnboarding';
// import AgentProfile from './components/agent/AgentProfile/AgentProfile';
// import AgentCommissions from './components/agent/AgentCommissions/AgentCommissions';
// import AgentPayouts from './components/agent/AgentPayouts/AgentPayouts';
// import AgentMaterials from './components/agent/AgentMaterials/AgentMaterials';

import PaymentProviderTicketing from './components/payment-provider/TicketingOverview/PaymentProviderTicketing';

import FirstStep from './components/onboarding/FirstStep';
import SecondStep from './components/onboarding/SecondStep';
import ThirdStep from './components/onboarding/ThirdStep';
import FourthStep from './components/onboarding/FourthStep';
import FifthStep from './components/onboarding/FifthStep';
import SixthStep from './components/onboarding/SixthStep';
import SeventhStep from './components/onboarding/SeventhStep';
import Finish from './components/onboarding/Finish';

import TicketingList from './components/shared/ticketing/TicketingList';
import AddEvent from './components/shared/ticketing/AddEvent/AddEvent';
import ProductVariant from './components/settings/product-settings/ProductCatalog/AddProduct/ProductVariant/ProductVariant';

import TicketingOverview from './components/overview/TicketingOverview/TicketingOverview';
import AgentsOverview from './components/overview/AgentsOverview/AgentsOverview';
import BookingOverview from './components/overview/BookingOverview/BookingOverview';

import SelectProduct from './components/order-process/SelectProduct';
import CheckoutPage from './components/order-process/CheckoutPage';
import ManualPaymentPage from './components/order-process/ManualPaymentPage';
import BillsPage from './components/order-process/InstallmentsList/BillsPage';
import OrdersOverview from './components/overview/OrdersOverview/OrdersOverview';
import MaterialSettings from './components/settings/business-settings/AgentSetup/MaterialSettings';

import Login from './components/auth/Login';
import Registration from './components/auth/Registration';
import AgentRegistration from './components/auth/AgentRegistration';
import EmailVerification from './components/auth/EmailVerification';
import EmailVerificationPage from "./components/auth/EmailVerification";

import RoleBasedRoute from './components/RoleBasedRoute';
import { useUserInfo } from 'hooks/useUserInfo';

const AppRoutes = () => {
    const { isAgent } = useUserInfo();

    return (
        <>
            <ScrollToTop />
            <Routes>
                {/* Public Routes */}
                <Route element={<PublicLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Registration />} />
                    <Route path="/register-agent" element={<AgentRegistration />} />
                    <Route path="/verify-email" element={<EmailVerification />} />
                    <Route path="/email-verification" element={<EmailVerificationPage />} />
                </Route>

                {/* Protected Routes */}
                <Route element={<ProtectedRoute><ProtectedLayout /></ProtectedRoute>}>
                    {isAgent ? (
                        // <Route path="/" element={<AgentDashboard />} />
                        <Route path="/" element={<MainDashboard />} />
                    ) : (
                        <Route path="/" element={<MainDashboard />} />
                    )}
                    <Route path="/dashboard" element={<MainDashboard />} />
                    <Route path="/services" element={<ServiceTable />} />
                    <Route path="/services-form" element={<ServiceForm />} />
                    <Route path="/spaces" element={<SpacesTable />} />
                    <Route path="/spaces-form" element={<SpacesForm />} />
                    <Route path="/merchant/subscription-plans" element={<MerchantSubscriptionPlans />} />
                    <Route path="/product-package" element={<ProductPackage />} />
                    <Route path="/select-product" element={<SelectProduct />} />
                    <Route path="/manual-payment" element={<ManualPaymentPage />} />
                    <Route path="/checkout" element={<CheckoutPage />} />
                    <Route path="/bills" element={<BillsPage />} />

                    {/*Settings*/}
                    <Route path='/settings' element={<SettingsLayout />}>
                        <Route path='materials' element={<MaterialSettings />} />
                        <Route index element={<SettingsPage />} />
                        <Route path='profile-settings' element={<ProfileSettings />} />
                        <Route path='profile-settings/edit' element={<EditProfileSettings />} />
                        <Route path='email-notifications' element={<EmailNotifications />} />
                        <Route path='landing-page' element={<LandingPage />} />
                        <Route path='branding' element={<Branding />} />
                        <Route path='compliance-documents' element={<ComplianceDocuments />} />
                        <Route path='subscriptions' element={<Subscriptions />} />
                        <Route path='subscriptions/create-subscription' element={<CreateSubscription />} />
                        <Route path='subscriptions/details/:id' element={<SubscriptionDetails />} />
                        <Route path='team-security' element={<TeamSecurity />} />
                        <Route path='freelance-agent' element={<FreelanceAgent />} />
                        <Route path='account-status' element={<AccountStatus />} />
                        <Route path='tax-details' element={<TaxDetails />} />
                        <Route path='custom-domain' element={<CustomDomain />} />
                        <Route path='store-setup' element={<StoreSetup />} />
                        <Route path='product-catalog' element={<ProductCatalog />} />
                        <Route path='product-catalog/:id/details' element={<ProductDetails />} />
                        <Route path='product-catalog/add-product' element={<AddProduct />} />
                        <Route path='coupon' element={<Coupons />} />
                        <Route path='shipping-rate' element={<ShippingRates />} />
                        <Route path='tax-rate' element={<TaxRates />} />
                        <Route path='pricing-table' element={<PricingTable />} />
                        <Route path='appointments' element={<Appointments />} />
                        <Route path='appointments/add-appointments' element={<AddAppointments />} />
                        <Route path='ticketing-setup' element={<TicketingList />} />
                        <Route path='ticketing-setup/add-ticketing' element={<AddEvent />} />
                        <Route path='ticketing-setup/edit-events' element={<AddEvent />} />
                        <Route path='orders' element={<OrdersList />} />
                        <Route path='transaction-history' element={<TransactionHistory />} />
                        <Route path='customer-records' element={<CustomerRecords />} />
                        <Route path='billing' element={<Billing />} />
                        <Route path='payment' element={<Payment />} />
                        <Route path='media-gallery' element={<MediaGallery />} />
                        <Route path='booking-setup' element={<BookingSetupList />} />
                        <Route path='booking-setup/add-booking' element={<BookingSetup />} />
                        <Route path='agent-setup' element={<AgentSetup />} />
                        <Route path='uploads' element={<Uploads />} />
                        <Route path='domain-setup' element={<DomainSetup />} />
                        <Route path='domain-verification' element={<DomainVerification />} />
                    </Route>

                    {/*Admin */}
                    <Route path='/admin' element={<SettingsLayout />}>
                        <Route index element={<Admin />} />
                        <Route path='merchants' element={<AdminMerchantList />} />
                        <Route path='settings/:id' element={<AdminSettings />} />
                        <Route path='settings/:id/company-name' element={<CompanyName />} />
                        <Route path='settings/:id/email' element={<AdminEmail />} />
                        <Route path='settings/:id/all-transactions' element={<AllTransactions />} />
                        <Route path='settings/:id/payment-processor' element={<TypeOfPaymentProcessor />} />
                        <Route path='settings/:id/public-url' element={<PublicUrlGenerated />} />
                        <Route path='settings/:id/business-details' element={<AdminBusinessDetails />} />
                        <Route path='settings/:id/recent-activity' element={<RecentActivity />} />
                        <Route path='settings/:id/products-created' element={<ListOfProductsCreated />} />
                        <Route path='settings/:id/compliance-documents' element={<AdminComplianceDocuments />} />
                        <Route path='settings/:id/public-details' element={<AdminPublicDetails />} />
                        <Route path='settings/:id/account-status' element={<AdminAccountStatus />} />
                        <Route path='settings/:id/security-settings' element={<AdminSecuritySettings />} />
                        <Route path='admin-dashboard' element={<AdminDashboard />} />
                        <Route path='onboarding' element={<AdminOnboarding />} />
                        <Route path='agreement' element={<AdminAgreementPage />} />
                        <Route path='email-management' element={<AdminEmailManagement />} />
                        <Route path='email-management/add-email' element={<AddEmail />} />
                        <Route path='subscriptions' element={<SubscriptionList />} />
                        <Route path='subscription-features' element={<AdminSubscriptionFeatures />} />
                        <Route path='admin-subscription-settings' element={<AdminSubscriptionsSettings />} />
                        <Route path='admin-subscription-plans' element={<AdminSubscriptionPlans />} />
                        <Route path='features-management' element={<FeatureManagement />} />
                        <Route path='collaboration-plans' element={<CollaborationPlans />} />
                        <Route path='payment-settings' element={<AdminPaymentSettings />} />
                        <Route path='notification-settings' element={<AdminNotificationSettings />} />
                        <Route path='revenue' element={<RevenueOverview />} />
                        <Route path='ticketing-transactions' element={<AdminTicketingTransactions />} />
                        <Route path='tier-usage' element={<AdminTierUsage />} />
                    </Route>

                    {/*Agent Pages*/}
                    {/*    <Route path="/agent-dashboard" element={<AgentDashboard />} />*/}
                    {/*    <Route path="/agent-onboarding" element={<AgentOnboarding />}>*/}
                    {/*        <Route path=":step" element={<AgentOnboarding />} />*/}
                    {/*    </Route>*/}
                    {/*    <Route path="/agent-profile" element={<AgentProfile />} />*/}
                    {/*    <Route path="/agent-commissions" element={<AgentCommissions />} />*/}
                    {/*    <Route path="/agent-payouts" element={<AgentPayouts />} />*/}
                    {/*    <Route path="/agent-materials" element={<AgentMaterials />} />*/}

                    {/*Payment Provider Page*/}
                    <Route path='/payment/ticketing' element={<PaymentProviderTicketing />} />

                    {/*Overview Pages*/}
                    <Route path='/order-overview' element={<OrdersOverview />} />
                    <Route path='/ticketing-overview' element={<TicketingOverview />} />
                    <Route path='/agents-overview' element={<AgentsOverview />} />
                    <Route path='/booking-overview' element={<BookingOverview />} />

                    <Route path='/page-builder' element={<PageBuilder />} />
                    <Route path='/dev' element={<ProductVariant />} />
                </Route>

                {/* Protected Routes with Header Only */}
                <Route element={<ProtectedRoute><HeaderOnlyLayout /></ProtectedRoute>}>
                    {/* Merchant onboarding */}
                    <Route path="/merchant-onboarding" element={<MerchantOnboarding />} />
                </Route>

                {/* Protected Routes with Simple Layout */}
                <Route element={<ProtectedRoute><SimpleLayout /></ProtectedRoute>}>
                    {/* Merchant onboarding steps */}
                    <Route path='/first' element={<FirstStep />} />
                    <Route path='/second' element={<SecondStep />} />
                    <Route path='/third' element={<ThirdStep />} />
                    <Route path='/fourth' element={<FourthStep />} />
                    <Route path='/fifth' element={<FifthStep />} />
                    <Route path='/sixth' element={<SixthStep />} />
                    <Route path='/seventh' element={<SeventhStep />} />
                    <Route path='/finish' element={<Finish />} />
                </Route>
            </Routes>
        </>
    );
};

export default AppRoutes;
