import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { authService } from '../services/auth.service';
import { logger } from '../utils/logger';

const KeycloakContext = createContext(null);

export const useKeycloak = () => {
    const context = useContext(KeycloakContext);
    if (!context) {
        throw new Error('useKeycloak must be used within a KeycloakProvider');
    }
    return context;
};

export const KeycloakProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [organizationRoles, setOrganizationRoles] = useState([]);
    const serviceWorkerRef = useRef(null);
    const logoutRef = useRef(null);
    const userEmailRef = useRef(null); // Add a ref to track user email changes

    const registerServiceWorker = useCallback(async () => {
        if ('serviceWorker' in navigator) {
            try {
                const registration = await navigator.serviceWorker.register('/tokenRefreshWorker.js');
                serviceWorkerRef.current = registration;
                
                // Send initial token data
                const tokenExpiryTime = parseInt(localStorage.getItem('token_expiry'));
                const lastActivityTime = parseInt(localStorage.getItem('last_activity_time') || new Date().getTime());
                registration.active?.postMessage({ 
                    type: 'UPDATE_TOKEN_DATA',
                    tokenExpiryTime,
                    lastActivityTime
                });
                
                // Start the refresh check
                registration.active?.postMessage({ type: 'START_REFRESH_CHECK' });
                
                // Set up message listener
                navigator.serviceWorker.addEventListener('message', async (event) => {
                    if (event.data.type === 'REQUEST_TOKEN_DATA') {
                        // Service worker requested token data
                        const tokenExpiryTime = parseInt(localStorage.getItem('token_expiry'));
                        const lastActivityTime = parseInt(localStorage.getItem('last_activity_time') || new Date().getTime());
                        registration.active?.postMessage({ 
                            type: 'UPDATE_TOKEN_DATA',
                            tokenExpiryTime,
                            lastActivityTime
                        });
                    } else if (event.data.type === 'REFRESH_NEEDED') {
                        try {
                            const success = await authService.refreshToken();
                            if (!success) {
                                logger.warn('Token refresh failed, logging out');
                                await logoutRef.current();
                            } else {
                                // Send updated token data after successful refresh
                                const tokenExpiryTime = parseInt(localStorage.getItem('token_expiry'));
                                const lastActivityTime = parseInt(localStorage.getItem('last_activity_time') || new Date().getTime());
                                registration.active?.postMessage({ 
                                    type: 'UPDATE_TOKEN_DATA',
                                    tokenExpiryTime,
                                    lastActivityTime
                                });
                            }
                        } catch (error) {
                            logger.error('Error refreshing token:', error);
                            await logoutRef.current();
                        }
                    } else if (event.data.type === 'IDLE_TIMEOUT') {
                        logger.warn('User has been idle for too long, logging out', { 
                            idleTime: event.data.idleTime + ' minutes'
                        });
                        await logoutRef.current();
                    }
                });
            } catch (error) {
                logger.error('Failed to register service worker:', error);
            }
        }
    }, []);

    const cleanup = useCallback(() => {
        if (serviceWorkerRef.current) {
            serviceWorkerRef.current.active?.postMessage({ type: 'STOP_REFRESH_CHECK' });
            serviceWorkerRef.current.unregister();
            serviceWorkerRef.current = null;
        }
    }, []);

    const handleLogout = useCallback(async () => {
        try {
            await authService.logout();
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            cleanup();
            setIsAuthenticated(false);
            setUserInfo(null);
            setOrganizationRoles([]);
            userEmailRef.current = null; // Reset the email ref on logout
            window.location.href = '/login';
        }
    }, [cleanup]);

    // Store logout handler in ref to avoid circular dependency
    useEffect(() => {
        logoutRef.current = handleLogout;
    }, [handleLogout]);

    // Handle cleanup on unmount
    useEffect(() => {
        return () => {
            cleanup();
        };
    }, [cleanup]);

    // Track user activity
    useEffect(() => {
        const updateLastActivity = () => {
            localStorage.setItem('last_activity_time', new Date().getTime().toString());
        };

        // Track user interaction events
        const events = ['mousedown', 'keydown', 'scroll', 'touchstart', 'submit'];
        events.forEach(event => {
            window.addEventListener(event, updateLastActivity);
        });

        return () => {
            events.forEach(event => {
                window.removeEventListener(event, updateLastActivity);
            });
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            registerServiceWorker();
        } else {
            cleanup();
        }
        return cleanup;
    }, [isAuthenticated, registerServiceWorker, cleanup]);

    const loadUserInfo = useCallback(async () => {
        try {
            const info = await authService.getUserInfo();
            if (info) {
                // Check if the user email has changed
                if (userEmailRef.current && userEmailRef.current !== info.email) {
                    logger.info('User email has changed, updating context', {
                        previous: userEmailRef.current,
                        current: info.email
                    });
                    // Force reload to ensure all components get the updated user info
                    window.location.reload();
                    return true;
                }
                
                // Update the email ref
                userEmailRef.current = info.email;
                
                setUserInfo(info);
                setOrganizationRoles(info.organization_roles || []);
                return true;
            }
            return false;
        } catch (error) {
            console.error('Failed to load user info:', error);
            return false;
        }
    }, []);

    // Check authentication status on mount
    useEffect(() => {
        let mounted = true;

        const checkAuth = async () => {
            try {
                const token = authService.getAccessToken();
                const refreshToken = authService.getRefreshToken();

                if (!token && !refreshToken) {
                    if (mounted) {
                        setIsAuthenticated(false);
                        setIsLoading(false);
                    }
                    return;
                }

                const validToken = await authService.ensureValidToken();
                if (validToken && mounted) {
                    const userInfoLoaded = await loadUserInfo();
                    if (mounted) {
                        setIsAuthenticated(userInfoLoaded);
                    }
                }
            } catch (error) {
                console.error('Auth check failed:', error);
                if (mounted) {
                    setIsAuthenticated(false);
                    if (error.message.includes('Please log in')) {
                        window.location.href = '/login';
                    }
                }
            } finally {
                if (mounted) {
                    setIsLoading(false);
                }
            }
        };

        checkAuth();

        return () => {
            mounted = false;
        };
    }, [loadUserInfo]);

    const login = async (email, password) => {
        try {
            await authService.login(email, password);
            const userInfoLoaded = await loadUserInfo();
            if (userInfoLoaded) {
                setIsAuthenticated(true);
            }
            return true;
        } catch (error) {
            console.error('Login failed:', error);
            return false;
        }
    };

    const value = {
        isAuthenticated,
        isLoading,
        userInfo,
        organizationRoles,
        login,
        logout: handleLogout
    };

    return (
        <KeycloakContext.Provider value={value}>
            {children}
        </KeycloakContext.Provider>
    );
};

export default KeycloakContext;
