import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authService } from '../services/auth.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    await authService.ensureValidToken();
    const token = authService.getAccessToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all subscriptions
export const fetchSubscriptions = createAsyncThunk('subscriptions/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/subscriptions`, { headers });
        return response.data.data;
    } catch (error) {
        if (!authService.isAuthenticated()) {
            authService.redirectToLogin();
        }
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new subscription
export const createSubscription = createAsyncThunk('subscriptions/create', async (subscriptionData, { rejectWithValue }) => {
    try {
        if (!subscriptionData.name || !subscriptionData.description || !subscriptionData.organization_id || !subscriptionData.space_id) {
            throw new Error('Name, description, organization ID, and space ID are required fields');
        }

        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/subscriptions`, subscriptionData, { headers });

        if (!response.data || !response.data.data || !response.data.data.id) {
            throw new Error('Server response is missing subscription ID');
        }

        return response.data.data;
    } catch (error) {
        if (!authService.isAuthenticated()) {
            authService.redirectToLogin();
        }
        console.error('Error creating subscription:', error);
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific subscription by ID
export const fetchSubscriptionById = createAsyncThunk('subscriptions/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/subscriptions/${id}`, { headers });
        return response.data;
    } catch (error) {
        if (!authService.isAuthenticated()) {
            authService.redirectToLogin();
        }
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update a subscription by ID
export const updateSubscription = createAsyncThunk('subscriptions/update', async ({ id, subscriptionData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/subscriptions/${id}`, subscriptionData, { headers });
        return response.data;
    } catch (error) {
        if (!authService.isAuthenticated()) {
            authService.redirectToLogin();
        }
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update subscription status
export const updateSubscriptionStatus = createAsyncThunk('subscriptions/updateStatus', async ({ id, entityId, statusData }, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.patch(`${API_BASE_URL}/subscriptions/${id}/status/${entityId}`, statusData, { headers });
        return response.data;
    } catch (error) {
        if (!authService.isAuthenticated()) {
            authService.redirectToLogin();
        }
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete a subscription by ID
export const deleteSubscription = createAsyncThunk('subscriptions/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/subscriptions/${id}`, { headers });
        return id;
    } catch (error) {
        if (!authService.isAuthenticated()) {
            authService.redirectToLogin();
        }
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Subscriptions Operations
const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState: {
        subscriptions: {
            page: 0,
            limit: 10,
            total_items: 0,
            total_pages: 0,
            data: []
        },
        selectedSubscription: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubscriptions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSubscriptions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.subscriptions = action.payload; // Store the entire response object
            })
            .addCase(fetchSubscriptions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch subscriptions';
            })

            .addCase(createSubscription.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createSubscription.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.subscriptions.push(action.payload);
            })
            .addCase(createSubscription.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create subscription';
            })

            .addCase(fetchSubscriptionById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSubscriptionById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedSubscription = action.payload;
            })
            .addCase(fetchSubscriptionById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch subscription by ID';
            })

            .addCase(updateSubscription.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateSubscription.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.subscriptions.findIndex(subscription => subscription.id === action.payload.id);
                if (index !== -1) state.subscriptions[index] = action.payload;
                if (state.selectedSubscription && state.selectedSubscription.id === action.payload.id) {
                    state.selectedSubscription = action.payload;
                }
            })
            .addCase(updateSubscription.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update subscription';
            })

            .addCase(updateSubscriptionStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateSubscriptionStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.subscriptions.findIndex(subscription => subscription.id === action.payload.id);
                if (index !== -1) state.subscriptions[index] = action.payload;
                if (state.selectedSubscription && state.selectedSubscription.id === action.payload.id) {
                    state.selectedSubscription = action.payload;
                }
            })
            .addCase(updateSubscriptionStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update subscription status';
            })

            .addCase(deleteSubscription.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteSubscription.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.subscriptions = state.subscriptions.filter(subscription => subscription.id !== action.payload);
                if (state.selectedSubscription && state.selectedSubscription.id === action.payload) {
                    state.selectedSubscription = null;
                }
            })
            .addCase(deleteSubscription.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete subscription';
            });
    },
});

export default subscriptionsSlice.reducer;