import React, { useState } from "react";
import { 
  Box, 
  Button, 
  Divider, 
  Paper, 
  Typography, 
  Stepper, 
  Step, 
  StepLabel, 
  Link, 
  Checkbox, 
  FormControlLabel, 
  Dialog, 
  DialogContent, 
  DialogActions, 
  Container, 
  Tooltip 
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Verification from "../merchant/Verification";
import {agreements} from "./Agreements";
import { useKeycloak } from "../../contexts/KeycloakContext";
import {setCurrentStep, updateOnboardingRecord} from "../../redux/accountOnboardingSlice";
import {useDispatch} from "react-redux";

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];

const SecondStep = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(1);
    const [isTosChecked, setIsTosChecked] = useState(false);
    const [openTosDialog, setOpenTosDialog] = useState(false);
    const [currentAgreementIndex, setCurrentAgreementIndex] = useState(0);
    const [uploadStatus, setUploadStatus] = useState({ hasImages: false, hasDocuments: false });
    const { userInfo } = useKeycloak();
    const navigate = useNavigate();

    const handleCancel = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            dispatch(setCurrentStep('first'));
            navigate('/first');
        }
    };

    const handleConfirm = async () => {
        if (activeStep < steps.length - 1) {
            dispatch(setCurrentStep('third'));
            try {
                const result = await dispatch(updateOnboardingRecord({
                    email: userInfo?.email,
                    onboardingData: {
                        current_step: 'third',
                        completed_steps: ['first', 'second']
                    }
                })).unwrap();
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                navigate('/third');
            } catch (error) {
                console.error('Failed to update server:', error);
            }
        }
    }

    const handleTosChange = (event) => {
        setIsTosChecked(event.target.checked);
    };

    const handleOpenTosDialog = () => {
        setOpenTosDialog(true);
        setCurrentAgreementIndex(0);
    };

    const handleCloseTosDialog = () => {
        setOpenTosDialog(false);
    };

    const handleNextAgreement = () => {
        if (currentAgreementIndex < agreements.length - 1) {
            setCurrentAgreementIndex((prevIndex) => prevIndex + 1);
        } else {
            setOpenTosDialog(false);
        }
    };

    const handleUploadsChange = (status) => {
        setUploadStatus(status);
    };

    const isNextButtonDisabled = !isTosChecked || !uploadStatus.hasImages || !uploadStatus.hasDocuments;
    const getNextButtonTooltip = () => {
        if (!isTosChecked) return "Please accept the Terms of Service to proceed";
        if (!uploadStatus.hasImages) return "Please upload a selfie with ID";
        if (!uploadStatus.hasDocuments) return "Please upload SSM documents";
        return "";
    };

    return (
        <>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} height='100vh' sx={{padding: '32px', margin: '32px', borderRadius: '12px'}}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    alignSelf: 'stretch',
                    width: '100%'
                }}>
                    <Box>
                        <Typography variant='h4'>
                            Upload SSM and Selfie with ID
                        </Typography>
                        <Typography variant='h5'>
                            Securely Upload Your SSM Certificate and ID Selfie to Proceed
                        </Typography>
                    </Box>
                </Box>

                <Divider sx={{my: 2}}/>

                <Verification onUploadsChange={handleUploadsChange}/>

                <Box sx={{mt: 2, display: 'flex', alignItems: 'flex-end'}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isTosChecked}
                                onChange={handleTosChange}
                                color="primary"
                            />
                        }
                        label={
                            <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                                <Typography variant="body2"
                                            sx={{lineHeight: '1.2', display: 'flex', alignItems: 'flex-end'}}>
                                    I agree to the{' '}
                                    <Link
                                        component="button"
                                        onClick={handleOpenTosDialog}
                                        underline="hover"
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'orange',
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            lineHeight: '1.2',
                                            ml: 0.5
                                        }}
                                    >
                                        Terms of Service
                                    </Link>.
                                </Typography>
                            </Box>
                        }
                    />
                </Box>

                <Box sx={{ml: 'auto', mt: 5, display: 'flex', gap: '12px', justifyContent: 'flex-end'}}>
                    <Button variant="cancel" onClick={handleCancel}>Previous</Button>
                    <Tooltip title={getNextButtonTooltip()} placement="top">
                        <span>
                            <Button
                                variant="save"
                                onClick={handleConfirm}
                                sx={{
                                    textTransform: 'none',
                                    height: '34px',
                                    '&.Mui-disabled': {
                                        color: 'white'
                                    }
                                }}
                                disabled={isNextButtonDisabled}
                            >
                                Next
                            </Button>
                        </span>
                    </Tooltip>
                </Box>

                <Dialog open={openTosDialog} onClose={handleCloseTosDialog}>
                    <DialogContent>
                        <Container maxWidth="md" sx={{my: 4}}>
                            <Typography variant="h2" sx={{fontSize: '2.5rem'}} gutterBottom align="center">
                                {agreements[currentAgreementIndex].title}
                            </Typography>

                            <Divider sx={{my: 3}}/>

                            {agreements[currentAgreementIndex].content.map((section, index) => (
                                <div key={index}>
                                    <Typography variant="h6" gutterBottom>
                                        {section.header}
                                    </Typography>
                                    <Typography variant="body1" paragraph>
                                        {section.text}
                                    </Typography>
                                </div>
                            ))}

                            <Divider sx={{my: 3}}/>
                        </Container>
                        <Typography variant="body2" color="textSecondary" align="center">
                            Last updated: 2/9/2024
                        </Typography>
                        <Typography align="center" variant="body2" sx={{mt: 2}}>
                            {`${currentAgreementIndex + 1} / ${agreements.length}`}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="cancel" onClick={handleCloseTosDialog}>
                            Close
                        </Button>
                        <Button variant="save" onClick={handleNextAgreement}>
                            {currentAgreementIndex < agreements.length - 1 ? 'Next' : 'Finish'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </>
    );
};

export default SecondStep;
