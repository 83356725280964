import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './theme/theme-provider';
import { Provider } from 'react-redux';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import { store } from './redux/store';
import { Helmet } from "react-helmet";
import { KeycloakProvider } from './contexts/KeycloakContext';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <ErrorBoundary>
        <Helmet>
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <React.StrictMode>
            <ThemeProvider>
                <Provider store={store}>
                    <BrowserRouter>
                        <KeycloakProvider>
                            <App />
                        </KeycloakProvider>
                    </BrowserRouter>
                </Provider>
            </ThemeProvider>
        </React.StrictMode>
    </ErrorBoundary>
);