import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    Divider,
    CircularProgress,
} from '@mui/material';
import { styled } from "@mui/system";
import { updatePackage } from "../../../redux/packagesSlice";

// Styled components
const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const EditPackageDialog = ({ open, handleClose, packageItem }) => {
    const dispatch = useDispatch();
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(null);
    const statusCheckInterval = useRef(null);

    const [packageData, setPackageData] = useState({
        product_variant_id: 20,
        name: '',
        description: '',
        amount: '',
        promotion_amount: '',
        currency_id: '',
        organization_id: 1,
    });

    // Initialize package data only when dialog opens or packageItem changes
    useEffect(() => {
        if (open && packageItem) {
            setPackageData({
                product_variant_id: 20,
                organization_id: 1,
                name: packageItem.name || '',
                description: packageItem.description || '',
                amount: packageItem.amount || '',
                promotion_amount: packageItem.promotion_amount || '',
                currency_id: packageItem.currency_id || '',
            });
        }
    }, [open, packageItem?.id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPackageData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const checkUpdateStatus = async (jobId) => {
        try {
            const response = await fetch(`/api/packages?jobId=${jobId}`);
            const data = await response.json();
            
            setUpdateStatus(data.data);
            
            if (['completed', 'failed'].includes(data.data.state)) {
                clearInterval(statusCheckInterval.current);
                setIsUpdating(false);
                
                if (data.data.state === 'completed') {
                    handleClose();
                }
            }
        } catch (error) {
            console.error('Error checking update status:', error);
        }
    };

    const handleSave = async () => {
        const id = packageItem ? packageItem.id : null;
        setIsUpdating(true);
        setUpdateStatus(null);
        
        try {
            const resultAction = await dispatch(updatePackage({ id, ...packageData }));
            if (updatePackage.fulfilled.match(resultAction)) {
                const { jobId } = resultAction.payload.data;
                
                // Start polling for job status
                statusCheckInterval.current = setInterval(() => {
                    checkUpdateStatus(jobId);
                }, 2000); // Check every 2 seconds
            } else {
                console.error('Failed to save package:', resultAction.payload);
                setIsUpdating(false);
            }
        } catch (error) {
            console.error('Failed to save package:', error);
            setIsUpdating(false);
        }
    };

    // Cleanup interval on unmount or dialog close
    useEffect(() => {
        return () => {
            if (statusCheckInterval.current) {
                clearInterval(statusCheckInterval.current);
            }
        };
    }, []);

    // Reset form when dialog closes
    useEffect(() => {
        if (!open) {
            setPackageData({
                product_variant_id: 20,
                name: '',
                description: '',
                amount: '',
                promotion_amount: '',
                currency_id: '',
                organization_id: 1,
            });
            setIsUpdating(false);
            setUpdateStatus(null);
            if (statusCheckInterval.current) {
                clearInterval(statusCheckInterval.current);
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                Edit Package
                {isUpdating && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                        <CircularProgress size={20} />
                        <Typography variant="body2" color="text.secondary">
                            {updateStatus?.state === 'completed' 
                                ? 'Update completed!' 
                                : updateStatus?.state === 'failed'
                                ? 'Update failed'
                                : 'Updating packages...'}
                        </Typography>
                        {updateStatus?.progress && (
                            <Typography variant="body2" color="text.secondary">
                                ({updateStatus.progress}%)
                            </Typography>
                        )}
                    </Box>
                )}
            </DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off">
                    {/* Package Name */}
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Package Name <RequiredIndicator>*</RequiredIndicator>
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter package name"
                            placeholder="Package name"
                            name="name"
                            value={packageData.name}
                            onChange={handleInputChange}
                            required
                            inputProps={{ maxLength: 255 }}
                        />
                    </Box>

                    <Divider />

                    {/* Description */}
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Description
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter package description"
                            multiline
                            rows={7}
                            name="description"
                            value={packageData.description}
                            onChange={handleInputChange}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    padding: '15px',
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0,
                                    },
                                },
                            }}
                        />
                    </Box>

                    <Divider />

                    {/* Amount */}
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Amount <RequiredIndicator>*</RequiredIndicator>
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter amount"
                            placeholder="0.00"
                            name="amount"
                            type="number"
                            value={packageData.amount}
                            onChange={handleInputChange}
                            required
                            inputProps={{ min: 0 }}
                        />
                    </Box>

                    <Divider />

                    {/* Promotion Amount */}
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Promotion Amount
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter promotion amount"
                            placeholder="0.00"
                            name="promotion_amount"
                            type="number"
                            value={packageData.promotion_amount}
                            onChange={handleInputChange}
                            inputProps={{ min: 0 }}
                        />
                    </Box>

                    <Divider />

                    {/* Currency */}
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Currency <RequiredIndicator>*</RequiredIndicator>
                        </TextFieldTitle>
                        <Select
                            fullWidth
                            name="currency_id"
                            value={packageData.currency_id}
                            onChange={handleInputChange}
                            required
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Select currency</MenuItem>
                            <MenuItem value={1}>USD</MenuItem>
                            <MenuItem value={2}>MYR</MenuItem>
                            {/* Add more currency options as needed */}
                        </Select>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="cancel">
                    Cancel
                </Button>
                <Button onClick={handleSave} variant="save">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditPackageDialog;