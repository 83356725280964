import { useState, useEffect } from 'react';
import { useKeycloak } from '../contexts/KeycloakContext';

// Updated role constants to match exactly with Keycloak roles
const ROLES = {
    // From realm_roles
    MERCHANT: 'merchants',
    AGENT: 'eventlah-agent',
    ADMIN: 'eventlah-admin', // Now from realm_roles
    PAYPROVIDER: 'payment-provider'
};

export const useUserInfo = () => {
    const { userInfo } = useKeycloak();
    const [roles, setRoles] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isMerchant, setIsMerchant] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
    const [isPaymentProvider, setIsPaymentProvider] = useState(false);

    useEffect(() => {
        if (userInfo) {
            // Get roles from both realm_roles and organization_roles
            const realmRoles = userInfo.realm_roles || [];
            const organizationRoles = userInfo.organization_roles || [];
            const resourceRoles = Object.values(userInfo.resource_access || {})
                .flatMap(resource => resource.roles || []);
            
            const allRoles = [...new Set([...realmRoles, ...organizationRoles, ...resourceRoles])];
            
            // Add debug logs
            // console.log('Role Sources:', {
            //     realmRoles,
            //     organizationRoles,
            //     resourceRoles,
            //     allRoles
            // });
            
            setRoles(allRoles);
            
            // Check for admin in realmRoles instead of organizationRoles
            setIsAdmin(realmRoles.includes(ROLES.ADMIN));
            setIsMerchant(realmRoles.includes(ROLES.MERCHANT));
            setIsAgent(realmRoles.includes(ROLES.AGENT));
            setIsPaymentProvider(realmRoles.includes(ROLES.PAYPROVIDER));

            // Add debug logs for role checks
            // console.log('Role Checks:', {
            //     adminCheck: realmRoles.includes(ROLES.ADMIN),
            //     merchantCheck: realmRoles.includes(ROLES.MERCHANT),
            //     agentCheck: realmRoles.includes(ROLES.AGENT),
            //     providerCheck: realmRoles.includes(ROLES.PAYPROVIDER),
            //     ROLES
            // });
        } else {
            // Reset state when userInfo is null
            setRoles([]);
            setIsAdmin(false);
            setIsMerchant(false);
            setIsAgent(false);
            setIsPaymentProvider(false);
        }
    }, [userInfo]);

    return {
        roles,
        isAdmin,
        isMerchant,
        isAgent,
        isPaymentProvider,
        userInfo,
        hasRole: (role) => roles.includes(role)
    };
};
