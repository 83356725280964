import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    Box,
    Typography,
    IconButton,
    Paper,
    TextField,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Tabs,
    Tab,
    CircularProgress,
    Skeleton
} from '@mui/material';
import { DeleteIcon, EditIcon } from "../shared/IconButtons";
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import { fetchAdminOrganizations, deleteAdminOrganization } from '../../redux/adminOrganizationSlice';

const Admin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [organizationToDelete, setOrganizationToDelete] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const { organizations = [], pagination = {}, status = 'idle', error = null } = useSelector(state => state.adminOrganizations || {});
    const tabStatus = ['1', '2', '3'];

    useEffect(() => {
        dispatch(fetchAdminOrganizations({ 
            page: paginationModel.page + 1,
            limit: paginationModel.pageSize,
            status: tabStatus[currentTab] 
        })).then(() => {
            setIsInitialLoad(false);
        });
    }, [dispatch, currentTab, paginationModel.page, paginationModel.pageSize]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handleSettings = (id) => {
        navigate(`/admin/settings/${id}`);
    };

    const handleEdit = (organization) => {
        console.log('edit', organization);
    };

    const handleDeleteClick = (organization) => {
        setOrganizationToDelete(organization);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (organizationToDelete) {
            await dispatch(deleteAdminOrganization(organizationToDelete.id));
            dispatch(fetchAdminOrganizations({ 
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
                status: tabStatus[currentTab] 
            }));
        }
        setDeleteDialogOpen(false);
        setOrganizationToDelete(null);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setOrganizationToDelete(null);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'name', headerName: 'Company', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'status', headerName: 'Status', width: 130 },
        {
            field: 'actions',
            headerName: '',
            flex: 0.7,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="settings" onClick={() => handleSettings(params.row.id)}>
                        <SettingsIcon />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )
        }
    ];

    if (isInitialLoad) {
        return (
            <Box sx={{ width: '100%' }}>
                <Skeleton variant="rectangular" width="100%" height={400} />
            </Box>
        );
    }

    if (status === 'failed') {
        return (
            <Box sx={{ padding: '32px' }}>
                <Typography color="error" variant="h6">
                    Error: {typeof error === 'string' ? error : 'Failed to load organizations'}
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4">Admin</Typography>
                <TextField
                    variant="outlined"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    size="small"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '34px',
                            '& input': {
                                height: '34px',
                                padding: '0 14px',
                            },
                            '& .MuiInputBase-input': {
                                height: '34px',
                                padding: '0 14px',
                            }
                        }
                    }}
                />
            </Box>

            <Divider />

            <Tabs value={currentTab} onChange={handleTabChange} aria-label="organization status tabs">
                <Tab label="Pending" />
                <Tab label="Verified" />
                <Tab label="Rejected" />
            </Tabs>

            <Paper
                sx={{
                    width: "100%",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    {`${tabStatus[currentTab]} Organizations`}
                </Typography>
                <DataGrid
                    rows={organizations}
                    columns={columns}
                    rowCount={pagination.totalCount || 0}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationModelChange}
                    pageSizeOptions={[5, 10, 20]}
                    loading={status === 'loading'}
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbar }}
                    autoHeight
                />
            </Paper>

            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Organization"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this organization? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Admin;