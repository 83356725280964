import React, {useEffect, useState} from 'react';
import {
    Box,
    Typography,
    Button,
    Tabs,
    Tab,
    TextField,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Refresh, Home, Edit, Search, OpenInNew, Delete } from "@mui/icons-material";
import axios from "axios";
import { Snackbar, Alert } from '@mui/material';
import PermissionGate from '../../../shared/PermissionGate';
import { useKeycloak } from '../../../../contexts/KeycloakContext';
import axiosInstance from '../../../../utils/axios';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    paddingLeft: '40px',
  },
});

// Function to determine if a domain type exists in the list
const hasDomainType = (domains, type) => {
    return domains.some(domain => domain.type === type);
};

// Function to determine which domain should show the home icon
const shouldShowHomeIcon = (domains, domain) => {
    if (domain.type === 'Domain') {
        return true;
    }
    if (domain.type === 'Subdomain' && !hasDomainType(domains, 'Domain')) {
        return true;
    }
    if (domain.type === 'Default' && !hasDomainType(domains, 'Domain') && !hasDomainType(domains, 'Subdomain')) {
        return true;
    }
    return false;
};

const DomainSetup = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [domains, setDomains] = useState([]);
    const [id, setId] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [domainToDelete, setDomainToDelete] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const { isAuthenticated, userInfo } = useKeycloak();

    useEffect(() => {
        fetchDomains();
    }, [userInfo]);

    const fetchDomains = async () => {

        if (!isAuthenticated) {
            console.error('User is not authenticated');
            return;
        }

        try {
            setLoading(true);
            if (!userInfo?.organization_id) {
                setError('Organization ID not found');
                setLoading(false);
                return;
            }
            const response = await axiosInstance.get(`${BASE_API_URL}/organization-domains?organization_id=${userInfo.organization_id}`);
            
            // Sort domains by type: Default first, then Subdomain, then Domain
            const sortedDomains = response.data.data.domains.sort((a, b) => {
                const typeOrder = { 'Default': 0, 'Subdomain': 1, 'Domain': 2 };
                return typeOrder[a.type] - typeOrder[b.type];
            });
            
            setDomains(sortedDomains);
            setId(response.data.data.id);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch domains');
            setLoading(false);
        }
    };

    const handleEdit = (id, domainName, type, organizationId) => {
        navigate(`/settings/domain-verification`, {
            state: {
                id: id,
                domain: domainName,
                type: type,
                organizationId: organizationId
            }
        });
    };

    const handleDelete = (domain) => {
        setDomainToDelete(domain);
        setOpenConfirmDialog(true);
    };

    const confirmDelete = async () => {
        try {
            await axiosInstance.delete(`${BASE_API_URL}/organization-domains?id=${domainToDelete.id}&organization_id=${userInfo.organization_id}`);
            setDomains(domains.filter(domain => domain.id !== domainToDelete.id));
            setOpenConfirmDialog(false);
            setSnackbar({
                open: true,
                message: 'Domain deleted successfully',
                severity: 'success'
            });
        } catch (err) {
            setError('Failed to delete domain');
            setSnackbar({
                open: true,
                message: 'Failed to delete domain',
                severity: 'error'
            });
        }
    };

    const handleRedirect = (domainName) => {
        const url = `https://${domainName}`;
        window.open(url, '_blank');
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Box>
                    <Typography variant="h4" component="h1" gutterBottom>Domains</Typography>
                    <Typography variant="body1" color="text.secondary">Manage and configure your domain settings</Typography>
                </Box>
                <PermissionGate 
                    featureCode="CRTDOM"
                    fallback={null}
                >
                    <Button variant="save" color="primary" onClick={() => handleEdit('newDomain', '', '', userInfo?.organization_id)}>
                        Connect existing domain
                    </Button>
                </PermissionGate>
            </Box>

            <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)} sx={{ mb: 3 }}>
                <Tab label="Connect existing domain" />
                {/*<Tab label="Transfer Domain" />*/}
            </Tabs>

            <Box sx={{ position: 'relative', mb: 3 }}>
                <Search style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: 'action.active' }} size={20} />
                <StyledTextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search domains"
                />
            </Box>

            <List>
                {domains.map((domain, index) => (
                    <ListItem
                        key={index}
                        component={Paper}
                        elevation={0}
                        sx={{
                            mb: 2,
                            '&:hover': { bgcolor: 'action.hover' }
                        }}
                    >
                        <ListItemIcon>
                            {shouldShowHomeIcon(domains, domain) ? <Home /> : <Refresh />}
                        </ListItemIcon>
                        <ListItemText
                            primary={domain.name}
                            secondary={domain.type}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                            <Typography
                                variant="caption"
                                component="span"
                                sx={{
                                    px: 0.75,
                                    py: 0.25,
                                    borderRadius: 1,
                                    fontSize: '0.65rem',
                                    fontWeight: 'medium',
                                    textTransform: 'uppercase',
                                    bgcolor: domain.status === 'active' ? 'success.light' :
                                        domain.status === 'Pending' ? 'warning.light' : 'error.light',
                                    color: domain.status === 'active' ? 'success.dark' :
                                        domain.status === 'Pending' ? 'warning.dark' : 'error.dark'
                                }}
                            >
                                {domain.status}
                            </Typography>
                        </Box>
                        <PermissionGate 
                            featureCode="UPDDOM"
                            fallback={null}
                        >
                            <Tooltip title={domain.type === 'Default' ? "Cannot edit default URL" : ""}>
                                <span>
                                    <IconButton 
                                        edge="end" 
                                        aria-label="edit" 
                                        onClick={() => handleEdit(domain.id, domain.name, domain.type, userInfo?.organization_id)} 
                                        sx={{ mr: 1 }}
                                        disabled={domain.type === 'Default'}
                                    >
                                        <Edit />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate 
                            featureCode="DELDOM"
                            fallback={null}
                        >
                            <Tooltip title={domain.type === 'Default' ? "Cannot delete default URL" : ""}>
                                <span>
                                    <IconButton 
                                        edge="end" 
                                        aria-label="delete" 
                                        onClick={() => handleDelete(domain)} 
                                        sx={{ mr: 1 }}
                                        disabled={domain.type === 'Default'}
                                    >
                                        <Delete />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate 
                            featureCode="RDDOM"
                            fallback={null}
                        >
                            <IconButton edge="end" aria-label="external link" onClick={() => handleRedirect(domain.name)}>
                                <OpenInNew />
                            </IconButton>
                        </PermissionGate>
                    </ListItem>
                ))}
            </List>

            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the domain "{domainToDelete?.name}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="cancel" onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
                    <Button variant="save" onClick={confirmDelete} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>

        </Box>
    );
};

export default DomainSetup;
