import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { 
    Box, 
    Paper, 
    Typography, 
    Stepper, 
    Step, 
    StepLabel, 
    Alert, 
    Divider, 
    CircularProgress,
    Button,
    Chip
} from "@mui/material";
import {
    CreditCard as CreditCardIcon,
    Add as AddIcon
} from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { setCurrentStep, updateOnboardingRecord } from "../../redux/accountOnboardingSlice";
import { authService } from '../../services/auth.service';
import CardValidationTerms from './CardValidationTerms';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from '../../utils/axios';
import AddCardDialog from './AddCardDialog';

// Initialize Stripe with error handling
const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
if (!stripeKey) {
    console.error('Stripe public key is not set in environment variables');
}
const stripePromise = loadStripe(stripeKey);

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];

const FourthStep = () => {
    const [activeStep, setActiveStep] = useState(3);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stripeError, setStripeError] = useState(!stripeKey);
    const [loading, setLoading] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const [openCardDialog, setOpenCardDialog] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkExistingPaymentMethod = async () => {
            try {
                const token = await authService.ensureValidToken();
                const userInfo = await authService.getUserInfo();
                
                // If user doesn't have a stripeCustomerId yet, just set loading to false
                if (!userInfo.stripeCustomerId) {
                    setLoading(false);
                    return;
                }
                
                const { data } = await axios.get('/stripe/payment-methods', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                
                if (data && data.length > 0) {
                    setPaymentMethods(data);
                    setDefaultPaymentMethod(data[0]);
                }
            } catch (err) {
                // Only set error if it's not related to missing stripeCustomerId
                if (err.response?.status !== 404) {
                    console.error('Error fetching payment methods:', err);
                    setError('Failed to check existing payment methods. Please try again.');
                }
            } finally {
                setLoading(false);
            }
        };

        checkExistingPaymentMethod();
    }, []);

    const handlePaymentMethodAdded = async (paymentMethodId) => {
        try {
            const token = await authService.ensureValidToken();
            const { data: updatedPaymentMethods } = await axios.get('/stripe/payment-methods', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setPaymentMethods(updatedPaymentMethods);
            setDefaultPaymentMethod(updatedPaymentMethods[0]);
            setOpenCardDialog(false);
        } catch (error) {
            console.error('Error updating payment methods:', error);
            setError('Failed to update payment methods. Please try again.');
        }
    };

    const handleRemoveCard = async (paymentMethodId) => {
        try {
            const token = await authService.ensureValidToken();
            await axios.delete(`/stripe/payment-methods?paymentMethodId=${paymentMethodId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            const { data: updatedPaymentMethods } = await axios.get('/stripe/payment-methods', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            setPaymentMethods(updatedPaymentMethods);
            setDefaultPaymentMethod(updatedPaymentMethods.length > 0 ? updatedPaymentMethods[0] : null);
        } catch (error) {
            console.error('Error removing card:', error);
            setError('Failed to remove card. Please try again.');
        }
    };

    const handleSetDefaultCard = async (paymentMethodId) => {
        try {
            const token = await authService.ensureValidToken();
            await axios.post('/stripe/payment-methods', {
                paymentMethodId,
                setAsDefault: true
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            const { data: updatedPaymentMethods } = await axios.get('/stripe/payment-methods', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            setPaymentMethods(updatedPaymentMethods);
            setDefaultPaymentMethod(updatedPaymentMethods[0]);
        } catch (error) {
            console.error('Error setting default payment method:', error);
            setError('Failed to set default card. Please try again.');
        }
    };

    const handleNext = async () => {
        try {
            const userInfo = await authService.getUserInfo();
            await dispatch(updateOnboardingRecord({
                email: userInfo.email,
                onboardingData: {
                    current_step: 'fifth',
                    completed_steps: ['first', 'second', 'third', 'fourth']
                }
            })).unwrap();
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            dispatch(setCurrentStep('fifth'));
            navigate('/fifth');
        } catch (error) {
            console.error('Failed to update onboarding status:', error);
            setError('Failed to update onboarding status. Please try again.');
        }
    };

    const handlePrevious = async () => {
        try {
            const userInfo = await authService.getUserInfo();
            await dispatch(updateOnboardingRecord({
                email: userInfo.email,
                onboardingData: {
                    current_step: 'third',
                    completed_steps: ['first', 'second']
                }
            })).unwrap();
            dispatch(setCurrentStep('third'));
            navigate('/third');
        } catch (error) {
            console.error('Failed to update onboarding status:', error);
            setError('Failed to update onboarding status. Please try again.');
        }
    };

    const getCardIcon = (brand) => {
        switch (brand?.toLowerCase()) {
            case 'visa':
                return '💳 Visa';
            case 'mastercard':
                return '💳 Mastercard';
            case 'amex':
                return '💳 Amex';
            default:
                return '💳';
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Stepper activeStep={3} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, margin: '32px', borderRadius: '12px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', mb: 3 }}>
                    <Typography variant='h4'>
                        Payment Method Setup
                    </Typography>
                    <Typography variant='h5'>
                        {defaultPaymentMethod ? 'Payment method is ready' : 'Add your payment method to continue.'}
                    </Typography>
                </Box>

                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                {stripeError ? (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        Unable to load payment system. Please contact support.
                    </Alert>
                ) : (
                    <>
                        <Alert severity="info" sx={{ mb: 3, fontSize: '0.875rem', '& .MuiAlert-message': { width: '100%' } }}>
                            {defaultPaymentMethod 
                                ? 'Your payment method has been verified and is ready for use.'
                                : 'Please add a valid payment method to proceed. A validation charge of RM1 will be processed and automatically refunded.'}
                        </Alert>

                        <Paper sx={{ p: 3, mb: 4 }}>
                            {paymentMethods.length > 0 ? (
                                paymentMethods.map((method) => (
                                    <Box 
                                        key={method.id} 
                                        sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            justifyContent: 'space-between',
                                            mb: 2,
                                            p: 2,
                                            border: '1px solid',
                                            borderColor: method.id === defaultPaymentMethod?.id ? 'primary.main' : 'divider',
                                            borderRadius: 1,
                                            bgcolor: method.id === defaultPaymentMethod?.id ? 'primary.lighter' : 'background.paper'
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ mr: 2 }}>
                                                <Typography variant="h6">{getCardIcon(method.card.brand)}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5 }}>
                                                    {method.card.brand.charAt(0).toUpperCase() + method.card.brand.slice(1)} •••• {method.card.last4}
                                                    {method.id === defaultPaymentMethod?.id && (
                                                        <Chip 
                                                            label="Default" 
                                                            size="small" 
                                                            color="primary" 
                                                            sx={{ ml: 1 }}
                                                        />
                                                    )}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Expires {method.card.exp_month.toString().padStart(2, '0')}/{method.card.exp_year}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            {method.id !== defaultPaymentMethod?.id && (
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ mr: 1 }}
                                                    onClick={() => handleSetDefaultCard(method.id)}
                                                >
                                                    Set as Default
                                                </Button>
                                            )}
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                                onClick={() => handleRemoveCard(method.id)}
                                            >
                                                Remove
                                            </Button>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
                                    <CreditCardIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
                                    <Typography variant="h6" gutterBottom>
                                        Add Your Payment Method
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" align="center" sx={{ mb: 3 }}>
                                        Please add a payment method to continue with the onboarding process.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={() => setOpenCardDialog(true)}
                                    >
                                        Add Payment Method
                                    </Button>
                                </Box>
                            )}

                            {paymentMethods.length > 0 && (
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={() => setOpenCardDialog(true)}
                                    >
                                        Add Another Card
                                    </Button>
                                </Box>
                            )}
                        </Paper>

                        <Elements stripe={stripePromise}>
                            <AddCardDialog
                                open={openCardDialog}
                                onClose={() => setOpenCardDialog(false)}
                                onSuccess={handlePaymentMethodAdded}
                            />
                        </Elements>
                        
                        <CardValidationTerms />

                        <Box sx={{ display: 'flex', gap: '12px', justifyContent: 'flex-end', mt: 5 }}>
                            <Button 
                                variant="cancel" 
                                onClick={handlePrevious}
                            >
                                Previous
                            </Button>
                            <Button
                                variant="save"
                                onClick={handleNext}
                                disabled={!defaultPaymentMethod}
                                sx={{
                                    textTransform: 'none',
                                    height: '34px',
                                    '&.Mui-disabled': {
                                        color: 'white'
                                    }
                                }}
                            >
                                Next
                            </Button>
                        </Box>
                    </>
                )}
            </Paper>
        </>
    );
};

export default FourthStep;
