import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    IconButton,
    LinearProgress,
    Typography,
    Paper,
    ImageList,
    ImageListItem,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Skeleton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddFileIcon from "../shared/AddFileIcon";
import { fetchUploads, addUpload, deleteUpload } from '../../redux/uploadSlice';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const IMAGE_URL = 'https://s3.ap-southeast-5.amazonaws.com/my.merchants/';
import { authService } from '../../services/auth.service';
import { v4 as uuidv4 } from 'uuid';

const Verification = ({ onUploadsChange }) => {
    const dispatch = useDispatch();
    const [onboardingImages, setOnboardingImages] = useState([]);
    const [onboardingDocuments, setOnboardingDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({ open: false, id: null });
    const [selectedFile, setSelectedFile] = useState(null);

    // Notify parent component when uploads change
    useEffect(() => {
        if (onUploadsChange) {
            onUploadsChange({
                hasImages: onboardingImages.length > 0,
                hasDocuments: onboardingDocuments.length > 0
            });
        }
    }, [onboardingImages, onboardingDocuments, onUploadsChange]);

    useEffect(() => {
        const fetchUploads = async () => {
            try {
                const token = await authService.ensureValidToken();

                // Fetch images
                const imagesResponse = await fetch(`${API_BASE_URL}/uploads?type=onboarding_image`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const imagesData = await imagesResponse.json();

                // Fetch documents
                const documentsResponse = await fetch(`${API_BASE_URL}/uploads?type=onboarding_document`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const documentsData = await documentsResponse.json();

                setOnboardingImages(imagesData.data.uploads || []);
                setOnboardingDocuments(documentsData.data.uploads || []);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching uploads:', error);
                setError('Failed to fetch uploads');
                setLoading(false);
            }
        };

        fetchUploads();
    }, []);

    const handleFileUpload = async (event, fileType) => {
        const files = Array.from(event.target.files);
        if (files.length === 0) return;

        setUploading(true);
        setUploadError(null);

        try {
            // Get token and user info
            const token = await authService.ensureValidToken();
            const userInfo = await authService.getUserInfo();

            if (!userInfo?.organization_id) {
                throw new Error('Organization ID not found. Please try logging in again.');
            }

            for (const file of files) {
                try {
                    console.log('Processing file:', file.name, 'Type:', fileType);
                    
                    // Determine upload type based on fileType
                    const uploadType = fileType === 'selfie' ? 'onboarding_image' : 'onboarding_document';
                    
                    // Step 1: Get pre-signed URL from our API
                    const requestBody = {
                        fileType,
                        fileName: file.name,
                        contentType: file.type || (fileType === 'selfie' ? 'image/jpeg' : 'application/pdf'),
                        organizationId: userInfo.organization_id
                    };

                    console.log('Request body:', requestBody);

                    const presignedUrlResponse = await fetch(`${API_BASE_URL}/uploads?type=${uploadType}`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(requestBody),
                    });

                    if (!presignedUrlResponse.ok) {
                        const errorData = await presignedUrlResponse.json();
                        throw new Error(errorData.description || 'Failed to get pre-signed URL');
                    }

                    const { data: { signedUrl, uploadId, key, contentType } } = await presignedUrlResponse.json();

                    // Step 2: Upload to S3 using pre-signed URL
                    const uploadResponse = await fetch(signedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'Content-Type': contentType,
                        },
                    });

                    if (!uploadResponse.ok) {
                        throw new Error('Failed to upload file to S3');
                    }

                    // Step 3: Add to local state
                    const newUpload = {
                        id: uploadId,
                        key,
                        filename: file.name,
                        size: file.size,
                        contentType,
                        uploadedBy: userInfo.id,
                        organizationId: userInfo.organization_id,
                        uploadType,
                        fileType
                    };

                    if (uploadType === 'onboarding_image') {
                        setOnboardingImages(prev => [...prev, newUpload]);
                    } else {
                        setOnboardingDocuments(prev => [...prev, newUpload]);
                    }

                } catch (error) {
                    console.error('Error uploading file:', error);
                    setUploadError(error.message);
                }
            }
        } catch (error) {
            console.error('Error in file upload process:', error);
            setUploadError(error.message || 'Failed to upload files. Please try again.');
        } finally {
            setUploading(false);
        }
    };

    const handleFileClick = async (upload) => {
        if (!upload.contentType.startsWith('image/')) {
            // For documents, open in new tab
            window.open(`${IMAGE_URL}${upload.s3Key}`, '_blank');
        } else {
            // For images, show in modal
            setSelectedFile(upload);
        }
    };

    const handleCloseFileView = () => {
        setSelectedFile(null);
    };

    const handleDelete = (id) => {
        setConfirmDialog({ open: true, id });
    };

    const handleConfirmDelete = async () => {
        try {
            const token = await authService.ensureValidToken();
            const response = await fetch(`${API_BASE_URL}/uploads/${confirmDialog.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete file');
            }

            // Remove from local state
            setOnboardingImages(prev => prev.filter(upload => upload.id !== confirmDialog.id));
            setOnboardingDocuments(prev => prev.filter(upload => upload.id !== confirmDialog.id));
        } catch (err) {
            console.error("Error deleting upload:", err);
            setUploadError('Failed to delete file. Please try again.');
        } finally {
            setConfirmDialog({ open: false, id: null });
        }
    };

    const renderFileIcon = (fileName) => {
        const extension = fileName.toLowerCase().split('.').pop();
        if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
            return <ImageIcon />;
        } else if (extension === 'pdf') {
            return <PictureAsPdfIcon />;
        } else if (['doc', 'docx'].includes(extension)) {
            return <DescriptionIcon />;
        } else {
            return <InsertDriveFileIcon />;
        }
    };

    const renderUploadSection = (title, description, buttonText, acceptedFormats, fileType, uploads) => (
        <Paper elevation={3} sx={{ padding: 1, borderRadius: 2, mb: 2 }}>
            <Box display="flex" sx={{ backgroundColor: '#f4f6f8' }}>
                <Box padding="24px">
                    <AddFileIcon />
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                    <Typography
                        sx={{
                            color: 'var(--dark-500, #09090B)',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '20px',
                            paddingTop: '24px',
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            color: 'var(--Gray-500, #667085)',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '18px',
                        }}
                    >
                        {description}
                    </Typography>
                </Box>
                <Box sx={{ paddingTop: '24px', paddingRight: '24px' }}>
                    <Button
                        variant='cancel'
                        onClick={() => document.getElementById(`file-upload-input-${fileType}`).click()}
                    >
                        {buttonText}
                    </Button>
                    <input
                        id={`file-upload-input-${fileType}`}
                        type="file"
                        hidden
                        multiple
                        accept={acceptedFormats}
                        onChange={(e) => handleFileUpload(e, fileType)}
                    />
                </Box>
            </Box>

            {loading ? (
                <ImageList sx={{width: '100%', height: 'auto', marginTop: 2}} cols={3} rowHeight={164}>
                    {[...Array(6)].map((_, index) => (
                        <ImageListItem key={index}>
                            <Skeleton variant="rectangular" width="100%" height={164} />
                        </ImageListItem>
                    ))}
                </ImageList>
            ) : uploads && uploads.length > 0 ? (
                <ImageList sx={{width: '100%', height: 'auto', marginTop: 2}} cols={3} rowHeight={164}>
                    {uploads.map((upload) => (
                        <ImageListItem 
                            key={upload.id}
                            sx={{ 
                                cursor: 'pointer',
                                '&:hover': {
                                    opacity: 0.8
                                }
                            }}
                            onClick={() => handleFileClick(upload)}
                        >
                            {upload.contentType && upload.contentType.startsWith('image/') ? (
                                <Box
                                    sx={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '164px',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <img
                                        src={`${IMAGE_URL}${upload.s3Key}`}
                                        alt={upload.filename}
                                        loading="lazy"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '164px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#f0f0f0',
                                        padding: 2,
                                    }}
                                >
                                    {renderFileIcon(upload.filename)}
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 1,
                                            textAlign: 'center',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                    >
                                        {upload.filename}
                                    </Typography>
                                </Box>
                            )}
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: 5,
                                    right: 5,
                                    bgcolor: 'background.paper',
                                    '&:hover': {
                                        bgcolor: 'background.paper',
                                        opacity: 0.9
                                    }
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(upload.id);
                                }}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </ImageListItem>
                    ))}
                </ImageList>
            ) : null}
        </Paper>
    );

    return (
        <Box>
            {uploadError && (
                <Alert severity="error" sx={{ mb: 2 }} onClose={() => setUploadError(null)}>
                    {uploadError}
                </Alert>
            )}

            {renderUploadSection(
                "Biz Owner Selfie with ID",
                "Upload a selfie with your ID. Acceptable formats are JPG and PNG.",
                "+ Add Image",
                ".jpg,.jpeg,.png",
                "selfie",
                onboardingImages
            )}
            {renderUploadSection(
                "Company SSM Documents",
                "Upload the SSM documents for your company. Acceptable formats are PDF, DOC, and DOCX.",
                "+ Add Document",
                ".pdf,.doc,.docx",
                "ssm",
                onboardingDocuments
            )}

            {uploading && <LinearProgress />}

            {/* File Preview Dialog */}
            <Dialog
                open={!!selectedFile}
                onClose={handleCloseFileView}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    {selectedFile?.contentType.startsWith('image/') && (
                        <img
                            src={`${IMAGE_URL}${selectedFile.s3Key}`}
                            alt={selectedFile.filename}
                            style={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '80vh',
                                objectFit: 'contain'
                            }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFileView}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={confirmDialog.open}
                onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this file? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialog({ ...confirmDialog, open: false })} variant="cancel">Cancel</Button>
                    <Button onClick={handleConfirmDelete} variant="save" autoFocus>
                        {loading ? <CircularProgress size={24} /> : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Verification;