import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CssBaseline, CircularProgress } from '@mui/material';
import Header from './components/header/Header';
import Sidebar from './components/Sidebar';
import { fetchOrganizationProfiles } from './redux/organizationProfilesSlice';
import { fetchOnboardingRecords } from "./redux/accountOnboardingSlice";
import { fetchUserPermissions } from './redux/permissionsSlice';
import { ThemeProvider } from '@mui/material/styles';
import AppRoutes from './AppRoutes';
import { FormProvider } from "./components/context/FormContext";
import { createTheme } from './theme/create-theme';
import { StripeProvider } from './contexts/StripeContext';
import { useKeycloak } from './contexts/KeycloakContext';
import { reconfigureStore } from './redux/store';

const App = () => {
    const [appInitialized, setAppInitialized] = useState(false);
    const theme = createTheme();
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { userInfo, isAuthenticated, isLoading: keycloakLoading, organizationRoles } = useKeycloak();

    const { status: orgProfileStatus } = useSelector((state) => state.organizationProfiles);
    const { currentStep, status: onboardingStatus } = useSelector((state) => state.accountOnboarding);
    const { status: permissionsStatus } = useSelector((state) => state.permissions);

    const [isLoading, setIsLoading] = useState({
        orgProfiles: false,
        onboarding: false,
        permissions: false
    });

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        if (userInfo && !userInfo.organization_id) {
            navigate('/first');
        }
    }, [userInfo, navigate]);

    // Set app as initialized when keycloak finishes loading
    useEffect(() => {
        if (!keycloakLoading) {
            setAppInitialized(true);
        }
    }, [keycloakLoading]);

    // Reconfigure store based on user roles when authentication is determined
    useEffect(() => {
        if (!keycloakLoading && isAuthenticated && userInfo) {
            const isAdmin = hasRole('eventlah-admin');
            reconfigureStore(isAdmin);
        }
    }, [keycloakLoading, isAuthenticated, userInfo]);

    // Handle initial data fetching
    useEffect(() => {
        if (keycloakLoading || !isAuthenticated || !userInfo) {
            return;
        }

        const fetchInitialData = async () => {
            const organizationId = userInfo?.organization_id;
            if(!organizationId) {
                navigate('/first');
            }
            const userId = userInfo?.sub;

            // Fetch organization profiles if needed
            // if (orgProfileStatus === 'idle' && organizationId) {
            //     setIsLoading(prev => ({...prev, orgProfiles: true}));
            //     try {
            //         await dispatch(fetchOrganizationProfiles(organizationId)).unwrap();
            //     } catch (error) {
            //         console.error('Organization profiles fetch error:', error);
            //         setSnackbar({
            //             open: true,
            //             message: 'Failed to load organization data',
            //             severity: 'error'
            //         });
            //     } finally {
            //         setIsLoading(prev => ({...prev, orgProfiles: false}));
            //     }
            // }

            // Fetch onboarding records if needed
            const isAdmin = hasRole('eventlah-admin');
            if (!isAdmin) {
                setIsLoading(prev => ({...prev, onboarding: true}));
                try {
                    await dispatch(fetchOnboardingRecords(userInfo?.email)).unwrap();
                } catch (error) {
                    console.error('Onboarding records fetch error:', error);
                    setSnackbar({
                        open: true,
                        message: 'Failed to load onboarding data',
                        severity: 'error'
                    });
                } finally {
                    setIsLoading(prev => ({...prev, onboarding: false}));
                }
            }

            // Fetch user permissions if needed
            if (userId) {
                setIsLoading(prev => ({...prev, permissions: true}));
                try {
                    await dispatch(fetchUserPermissions(userId)).unwrap();
                } catch (error) {
                    console.error('Permissions fetch error:', error);
                    setSnackbar({
                        open: true,
                        message: 'Failed to load user permissions',
                        severity: 'error'
                    });
                } finally {
                    setIsLoading(prev => ({...prev, permissions: false}));
                }
            }
        };

        fetchInitialData();
    }, [
        isAuthenticated,
        dispatch,
        userInfo,
        navigate
    ]);

    // Function to check if user has a specific role
    const hasRole = (role) => {
        return userInfo?.realm_roles?.includes(role) || false;
    };

    // Handle routing based on authentication and role
    useEffect(() => {
        if (keycloakLoading || !isAuthenticated || !userInfo) {
            return;
        }

        const handleRouting = () => {
            const isMerchants = hasRole('merchants');
            const isAdmin = hasRole('eventlah-admin');
            const isAgent = hasRole('eventlah-agent');
            const currentPath = location.pathname;

            // If at root path or just logged in
            if (currentPath === '/' || currentPath === '/login') {
                // Admin users always redirect to dashboard
                if (isAdmin) {
                    navigate('/admin/admin-dashboard');
                    return;
                }

                // For merchants and agents, only redirect to dashboard if onboarding is completed
                if (currentStep === 'completed') {
                    navigate('/dashboard');
                    return;
                }
            }

            // Handle incomplete onboarding for non-admin users
            if (!isAdmin && currentStep && currentStep !== 'completed') {
                const onboardingPath = '/' + currentStep;
                if (currentPath !== onboardingPath) {
                    navigate(onboardingPath);
                }
            }
        };

        handleRouting();
    }, [appInitialized, currentStep, location.pathname, navigate, userInfo, isAuthenticated]);

    if (keycloakLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <FormProvider>
            <StripeProvider>
                {isAuthenticated ? (
                    <ThemeProvider theme={theme}>
                        <Box sx={{ display: 'flex' }}>
                            <CssBaseline />
                            <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                            <Sidebar open={isSidebarOpen} onClose={toggleSidebar} />
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    height: '100vh',
                                    overflow: 'auto',
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.grey[100]
                                            : theme.palette.grey[900],
                                }}
                            >
                                <AppRoutes />
                            </Box>
                        </Box>
                    </ThemeProvider>
                ) : (
                    <AppRoutes />
                )}
            </StripeProvider>
        </FormProvider>
    );
};

export default App;