import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { authService } from '../services/auth.service';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUploads = createAsyncThunk(
    'uploads/fetchUploads',
    async (_, { rejectWithValue }) => {
        try {
            const token = await authService.ensureValidToken();
            const userInfo = await authService.getUserInfo();
            
            const response = await axios.get(`${API_BASE_URL}/uploads?type=onboarding`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Organization-ID': userInfo?.organizationId
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred while fetching uploads');
        }
    }
);

export const addUpload = createAsyncThunk(
    'uploads/addUpload',
    async (uploadData, { rejectWithValue }) => {
        try {
            const { id, key, filename, size, contentType, uploadedBy, organizationId } = uploadData;
            console.info('Adding upload to store:', uploadData);

            return {
                id,
                key,
                filename,
                contentType,
                size,
                uploadedBy,
                organizationId,
                uploadType: 'onboarding'
            };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred while adding upload');
        }
    }
);

export const deleteUpload = createAsyncThunk(
    'uploads/deleteUpload',
    async (uploadId, { rejectWithValue }) => {
        try {
            const token = await authService.ensureValidToken();
            const userInfo = await authService.getUserInfo();
            
            await axios.delete(`${API_BASE_URL}/uploads/${uploadId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Organization-ID': userInfo?.organizationId
                }
            });
            return uploadId;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred while deleting upload');
        }
    }
);

const uploadsSlice = createSlice({
    name: 'uploads',
    initialState: {
        uploads: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUploads.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUploads.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.uploads = action.payload.data.uploads;
            })
            .addCase(fetchUploads.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(addUpload.fulfilled, (state, action) => {
                state.uploads.push(action.payload);
            })
            .addCase(deleteUpload.fulfilled, (state, action) => {
                state.uploads = state.uploads.filter(upload => upload.id !== action.payload);
            });
    }
});

export default uploadsSlice.reducer;
