/**
 * This file is a compatibility layer for components that were using keycloak-js directly.
 * It uses the authService under the hood, but provides the same API as the original keycloak.js file.
 */
import { authService } from './services/auth.service';

// Get the current user info from localStorage
const getUserInfo = () => {
    try {
        const token = authService.getAccessToken();
        if (!token) return null;
        
        // Try to parse the token to get user info
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        return tokenData;
    } catch (error) {
        console.error('Error getting user info from token:', error);
        return null;
    }
};

// Check if the user is authenticated
export const isAuthenticated = () => {
    return authService.isAuthenticated();
};

// Get the username from the token
export const getUsername = () => {
    const userInfo = getUserInfo();
    return userInfo?.preferred_username;
};

// Check if the user has a specific realm role
export const hasRole = (role) => {
    const userInfo = getUserInfo();
    return userInfo?.realm_access?.roles?.includes(role) || false;
};

// Get the organization ID from the token
export const getOrganizationId = () => {
    const userInfo = getUserInfo();
    return userInfo?.organization_id;
};

// Update the token if needed
export const updateToken = async (minValidity = 30) => {
    try {
        const refreshed = await authService.ensureValidToken();
        return !!refreshed;
    } catch (error) {
        console.error('Token refresh failed:', error);
        throw error;
    }
};

// Initialize keycloak - this is now a no-op since we're using authService
export const initKeycloak = async () => {
    // Check if we're already authenticated
    const isAuth = authService.isAuthenticated();
    return isAuth;
};

// Get the token
export const getToken = async () => {
    try {
        const token = await authService.ensureValidToken();
        return token;
    } catch (error) {
        console.error('Failed to get token:', error);
        return null;
    }
};

// Compatibility object that mimics the keycloak instance
const keycloak = {
    authenticated: isAuthenticated(),
    hasRealmRole: hasRole,
    tokenParsed: getUserInfo(),
    token: authService.getAccessToken(),
    updateToken,
    logout: authService.logout.bind(authService)
};

export default keycloak;
