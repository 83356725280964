import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Paper,
    Popover,
    Divider,
    TextField,
    Button,
    CircularProgress,
    Tabs,
    Tab,
    Alert,
    Snackbar,
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { styled } from '@mui/system';
import { ChromePicker } from 'react-color';
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';
import {
    fetchThemeComponents,
    updateThemeComponent,
    selectAllThemeComponents,
    selectThemeComponentsStatus,
    selectThemeComponentsError,
    selectUpdateStatus,
    selectUpdateError,
    resetUpdateStatus
} from '../../../../redux/themeComponentsSlice';
import { useKeycloak } from '../../../../contexts/KeycloakContext';
import { fetchOrganizationPublicDetails } from "../../../../redux/organizationPublicDetailsSlice";
import axiosInstance from '../../../../utils/axios';
import { authService } from '../../../../services/auth.service';

const s3Client = new S3Client({
    region: "ap-southeast-5",
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_BITBUCKET_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_BITBUCKET_SECRET_ACCESS_KEY
    },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),
}));

const PreviewPaper = styled(Paper)(({ theme }) => ({
    height: 'auto',
    overflowY: 'visible',
    display: 'flex',
    flexDirection: 'column',
}));

const ColorBox = styled(Box)(({ bgcolor }) => ({
    width: '24px',
    height: '24px',
    backgroundColor: bgcolor,
    cursor: 'pointer',
    border: '1px solid #000',
    borderRadius: '50%',
    marginRight: '8px',
}));

const defaultFormData = {
    logo: null,
    colors: {
        header: {
            background: '#000000'
        },
        body: {
            text: '#000000',
            buttons: '#ffeb3b',
            icons: '#000000',
            buttonLabelsAndOutlines: '#000000',
        },
        background: {
            background1: '#FFFFFF',
            background2: '#F8F9FA',
            border: '#DADCE0',
        },
    },
};

const Checkout = () => {
    const dispatch = useDispatch();
    const { userInfo } = useKeycloak();
    const themeComponents = useSelector(selectAllThemeComponents);
    const fetchStatus = useSelector(selectThemeComponentsStatus);
    const fetchError = useSelector(selectThemeComponentsError);
    const updateStatus = useSelector(selectUpdateStatus);
    const updateError = useSelector(selectUpdateError);

    const [formData, setFormData] = useState(defaultFormData);
    const [uploading, setUploading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
    const [activeColorField, setActiveColorField] = useState(null);
    const [activeTab, setActiveTab] = useState(0);

    // Fetch theme components when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Ensure we have a valid token
                await authService.ensureValidToken();
                
                const organizationId = userInfo?.organization_id;
                if (organizationId) {
                    await dispatch(fetchThemeComponents({
                        page: 1,
                        limit: 10,
                        organization_id: organizationId
                    }));
                }
            } catch (error) {
                console.error('Error fetching theme components:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Failed to fetch theme components. Please try again.');
                setSnackbarOpen(true);
            }
        };

        fetchData();
    }, [dispatch, userInfo]);

    // Update form data when theme components are loaded
    useEffect(() => {
        if (fetchStatus === 'succeeded' && themeComponents.length > 0) {
            const checkoutComponent = themeComponents.find(component => component.name === 'checkout');
            if (checkoutComponent) {
                const newFormData = {
                    ...defaultFormData,
                    logo: checkoutComponent.logo || defaultFormData.logo,
                    colors: {
                        header: {
                            background: checkoutComponent.header_background_color || defaultFormData.colors.header.background
                        },
                        body: {
                            text: checkoutComponent.body_text_color || defaultFormData.colors.body.text,
                            buttons: checkoutComponent.button_color || defaultFormData.colors.body.buttons,
                            icons: checkoutComponent.icon_color || defaultFormData.colors.body.icons,
                            buttonLabelsAndOutlines: checkoutComponent.button_label_color || defaultFormData.colors.body.buttonLabelsAndOutlines,
                        },
                        background: {
                            background1: checkoutComponent.background_color_1 || defaultFormData.colors.background.background1,
                            background2: checkoutComponent.background_color_2 || defaultFormData.colors.background.background2,
                            border: checkoutComponent.border_color || defaultFormData.colors.background.border,
                        },
                    }
                };
                setFormData(newFormData);
            }
        }
    }, [themeComponents, fetchStatus]);

    // Monitor update status
    useEffect(() => {
        if (updateStatus === 'succeeded') {
            setSnackbarSeverity('success');
            setSnackbarMessage('Changes saved successfully!');
            setSnackbarOpen(true);
            dispatch(resetUpdateStatus());
        } else if (updateStatus === 'failed') {
            setSnackbarSeverity('error');
            setSnackbarMessage(updateError || 'Failed to save changes.');
            setSnackbarOpen(true);
            dispatch(resetUpdateStatus());
        }
    }, [updateStatus, updateError, dispatch]);

    const handleColorChange = (color) => {
        if (!activeColorField) return;

        setFormData(prevData => ({
            ...prevData,
            colors: {
                ...prevData.colors,
                [activeColorField.group]: {
                    ...prevData.colors[activeColorField.group],
                    [activeColorField.field]: color.hex,
                },
            },
        }));
    };

    const handleColorBoxClick = (event, group, field) => {
        setColorPickerAnchor(event.currentTarget);
        setActiveColorField({ group, field });
    };

    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setUploading(true);
        try {
            const timestamp = Date.now();
            const uniqueId = uuidv4().slice(0, 6);
            const fileNameParts = file.name.split('.');
            const fileExtension = fileNameParts.pop();
            const fileName = fileNameParts.join('.');
            const uniqueFileName = `${fileName}_${timestamp}_${uniqueId}.${fileExtension}`;
            const organizationId = userInfo?.organization_id || '1';
            const key = `${organizationId}/${uniqueFileName}`;

            const params = {
                Bucket: "my.merchants",
                Key: key,
                Body: file,
                ContentType: file.type,
            };

            await s3Client.send(new PutObjectCommand(params));
            const fileUrl = `https://s3.ap-southeast-5.amazonaws.com/my.merchants/${encodeURIComponent(key)}`;

            const checkoutComponent = themeComponents.find(component => component.name === 'checkout');
            if (checkoutComponent) {
                await dispatch(updateThemeComponent({
                    id: checkoutComponent.id,
                    themeComponentData: { logo: fileUrl }
                }));

                setFormData(prevData => ({
                    ...prevData,
                    logo: fileUrl,
                }));

                setSnackbarSeverity('success');
                setSnackbarMessage('Logo uploaded successfully!');
                setSnackbarOpen(true);
            }
        } catch (err) {
            console.error("Error uploading file:", err);
            setSnackbarSeverity('error');
            setSnackbarMessage('Failed to upload logo.');
            setSnackbarOpen(true);
        } finally {
            setUploading(false);
        }
    };

    const handleSave = async () => {
        const organizationId = userInfo?.organization_id;
        if (!organizationId) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Organization ID not found. Please try logging in again.');
            setSnackbarOpen(true);
            return;
        }

        const checkoutComponent = themeComponents.find(component => component.name === 'checkout');
        if (!checkoutComponent) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Checkout component not found.');
            setSnackbarOpen(true);
            return;
        }

        try {
            const themeComponentData = {
                organization_id: organizationId,
                name: 'checkout',
                header_background_color: formData.colors.header.background,
                body_text_color: formData.colors.body.text,
                button_color: formData.colors.body.buttons,
                icon_color: formData.colors.body.icons,
                button_label_color: formData.colors.body.buttonLabelsAndOutlines,
                background_color_1: formData.colors.background.background1,
                background_color_2: formData.colors.background.background2,
                border_color: formData.colors.background.border,
                logo: typeof formData.logo === 'string' ? formData.logo : null,
            };

            console.log('Sending update with data:', themeComponentData);

            const result = await dispatch(updateThemeComponent({
                id: checkoutComponent.id,
                themeComponentData
            })).unwrap();

            if (result.code === 200 || result.code === 201) {
                setSnackbarSeverity('success');
                setSnackbarMessage('Theme updated successfully!');
                setSnackbarOpen(true);

                // Refresh the data
                await dispatch(fetchThemeComponents({
                    page: 1,
                    limit: 10,
                    organization_id: organizationId
                }));
            } else {
                throw new Error(result.description || 'Update failed');
            }
        } catch (error) {
            console.error('Save error:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage(error.message || 'Failed to update theme component.');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const renderColorOption = (group, field, label) => {
        const color = formData.colors[group][field];
        return (
            <Box display="flex" alignItems="center" mb={1}>
                <ColorBox
                    bgcolor={color || 'transparent'}
                    onClick={(e) => handleColorBoxClick(e, group, field)}
                />
                <Typography variant="body2">
                    {label} {color}
                </Typography>
            </Box>
        );
    };

    const textFieldStyle = {
        mb: 1,
        "& .MuiOutlinedInput-root": {
            backgroundColor: formData.colors.background.background2,
            "& fieldset": {
                borderColor: formData.colors.background.border,
            },
        },
        "& .MuiInputBase-input": {
            color: formData.colors.body.text,
        },
    };

    const StyledTabs = styled(Tabs)(({ theme }) => ({
        minHeight: '32px',
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        '& .MuiTabs-flexContainer': {
            gap: theme.spacing(1),
        },
    }));

    const StyledTab = styled(Tab)(({ theme }) => ({
        minHeight: '32px',
        minWidth: 'auto',
        padding: theme.spacing(0.5, 1.5),
        borderRadius: '8px',
        fontSize: '0.875rem',
        fontWeight: 500,
        textTransform: 'none',
        color: formData.colors.body.text,
        '&.Mui-selected': {
            color: '#000',
            backgroundColor: '#ffeb3b'
        }
    }));

    if (fetchStatus === 'loading') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (fetchStatus === 'failed') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">Error: {fetchError}</Typography>
            </Box>
        );
    }

    const renderTabContent = () => {
        if (activeTab === 0) {
            return null;
        } else {
            return (
                <>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value="Billing Form"
                        InputProps={{
                            startAdornment: <CreditCardIcon sx={{ mr: 1, color: formData.colors.body.icons }} />,
                            readOnly: true,
                        }}
                        sx={textFieldStyle}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        value="Shipping Form"
                        InputProps={{
                            startAdornment: <LocalShippingIcon sx={{ mr: 1, color: formData.colors.body.icons }} />,
                            readOnly: true,
                        }}
                        sx={textFieldStyle}
                    />
                </>
            );
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, minHeight: '100vh', gap: 2 }}>
            {/* Form Section */}
            <StyledPaper
                elevation={3}
                sx={{
                    flex: { xs: 1, md: 0.8 },
                    overflowY: 'auto',
                    borderRadius: '12px',
                    p: { xs: 2, md: 3 },
                }}
            >
                <Typography sx={{ fontSize: '16px', fontWeight: '700' }} gutterBottom>
                    Show for
                </Typography>
                <StyledTabs
                    value={activeTab}
                    onChange={handleTabChange}
                    sx={{
                        mb: 2,
                        padding: '4px',
                        borderRadius: '20px',
                    }}
                >
                    <StyledTab label="Event Checkout" disableRipple />
                    <StyledTab label="Product Checkout" disableRipple />
                </StyledTabs>

                <Divider sx={{ my: 2 }} />

                <Typography sx={{ fontSize: '16px', fontWeight: '700' }} gutterBottom>
                    Logo
                </Typography>
                <Typography variant="caption" gutterBottom>
                    Recommended image size: 250px x 250px
                </Typography>
                <br />
                <label htmlFor="raised-button-file">
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleLogoUpload}
                    />
                    <Button
                        variant="save"
                        component="span"
                        disabled={uploading}
                    >
                        {uploading ? <CircularProgress size={24} /> : 'Upload Logo'}
                    </Button>
                </label>

                <Typography sx={{ fontSize: '16px', fontWeight: '700', mt: 3, mb: 1 }} gutterBottom>
                    Colors
                </Typography>
                <Box mb={2}>
                    <Typography variant="subtitle1">Header</Typography>
                    {renderColorOption('header', 'background', 'Background color')}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">Body</Typography>
                    {renderColorOption('body', 'text', 'Text')}
                    {renderColorOption('body', 'buttons', 'Button')}
                    {renderColorOption('body', 'buttonLabelsAndOutlines', 'Button labels and outlines')}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">Background</Typography>
                    {renderColorOption('background', 'background1', 'Background 1')}
                    {renderColorOption('background', 'background2', 'Background 2')}
                    {renderColorOption('background', 'border', 'Border')}
                </Box>

                <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={updateStatus === 'loading'}
                    sx={{ mt: 2 }}
                >
                    {updateStatus === 'loading' ? <CircularProgress size={24} /> : 'Save Changes'}
                </Button>
            </StyledPaper>

            {/* Preview Section */}
            <PreviewPaper
                elevation={3}
                sx={{
                    flex: { xs: 1, md: 1 },
                    p: 3,
                    borderRadius: '12px',
                    backgroundColor: formData.colors.background.background1,
                    mt: { xs: 3, md: 0 }
                }}
            >
                <Box
                    sx={{
                        backgroundColor: formData.colors.header.background,
                        height: '55px',
                        width: 'auto',
                        mx: { xs: 0, md: -3 },
                        marginTop: { xs: 0, md: -3 },
                        marginBottom: 3,
                        boxShadow: 3,
                        display: 'flex',
                        alignItems: 'center',
                        px: 2,
                        justifyContent: 'space-between',
                        borderRadius: '12px 12px 0px 0px',
                    }}
                >
                    <Box>
                        {formData.logo ? (
                            <img src={formData.logo} alt="Company Logo" style={{ height: '40px', width: 'auto' }} />
                        ) : (
                            <Typography sx={{ fontWeight: '800', ml: 2 }}>Logo</Typography>
                        )}
                    </Box>
                </Box>

                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 1, color: formData.colors.body.text }}>
                    Confirm and Pay
                </Typography>

                <Divider sx={{ mb: 3, backgroundColor: formData.colors.background.border }} />

                <Typography variant="h6" gutterBottom sx={{ color: formData.colors.body.text }}>
                    Customer Information
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    value="Customer Information form"
                    InputProps={{
                        readOnly: true,
                    }}
                    sx={textFieldStyle}
                />

                {renderTabContent()}

                <Divider sx={{ my: 3, backgroundColor: formData.colors.background.border }} />

                <Typography variant="h6" gutterBottom sx={{ color: formData.colors.body.text }}>
                    Select Payment Method
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    value="Online Payment"
                    InputProps={{
                        startAdornment: <AccountBalanceIcon sx={{ mr: 1, color: formData.colors.body.icons }} />,
                        readOnly: true,
                    }}
                    sx={textFieldStyle}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    value="Credit / Debit Card"
                    InputProps={{
                        startAdornment: <CreditCardIcon sx={{ mr: 1, color: formData.colors.body.icons }} />,
                        readOnly: true,
                    }}
                    sx={textFieldStyle}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    value="Partial Payment"
                    InputProps={{
                        startAdornment: <PaymentsIcon sx={{ mr: 1, color: formData.colors.body.icons }} />,
                        readOnly: true,
                    }}
                    sx={textFieldStyle}
                />

                <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, marginTop: '24px', flexDirection: { xs: 'column', sm: 'row' }, gap: 1 }}>
                    <Button variant="cancel" sx={{ width: { xs: '100%', sm: 'auto' } }}>
                        Cancel Payment
                    </Button>
                    <Button
                        variant="save"
                        sx={{
                            minWidth: 150,
                            backgroundColor: formData.colors.body.buttons,
                            color: formData.colors.body.buttonLabelsAndOutlines,
                            '&:hover': {
                                backgroundColor: formData.colors.body.buttons,
                                opacity: 0.9,
                            }
                        }}
                    >
                        Confirm and Pay
                    </Button>
                </Box>
            </PreviewPaper>

            {/* Order Summary Section */}
            <PreviewPaper
                elevation={3}
                sx={{
                    flex: { xs: 1, md: 1 },
                    borderRadius: '12px',
                    mt: { xs: 3, md: 0 }
                }}
            >
                <Box sx={{ backgroundColor: formData.colors.background.background1, p: 1.5, borderRadius: '10px' }}>
                    {[1, 2, 3].map((index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: index > 1 ? 1.5 : 0 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: 60, height: 60, bgcolor: '#ccc', mr: 4 }} />
                                <Box>
                                    <Typography variant="subtitle1" sx={{ color: formData.colors.body.text }}>
                                        {activeTab === 0 ? `Event ${index}` : `Product ${index}`}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: formData.colors.body.text }}>
                                        Quantity: 1
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="body2" sx={{ color: formData.colors.body.text, textAlign: 'right' }}>
                                    RM199.99
                                </Typography>
                            </Box>
                        </Box>
                    ))}

                    <Box sx={{ backgroundColor: formData.colors.background.border, height: '1px', my: 1.5 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.75 }}>
                        <Typography variant="body2" sx={{ color: formData.colors.body.text }}>
                            Subtotal
                        </Typography>
                        <Typography variant="body2" sx={{ color: formData.colors.body.text }}>
                            RM599.97
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.75 }}>
                        <Typography variant="body2" sx={{ color: formData.colors.body.text }}>
                            Shipping
                        </Typography>
                        <Typography variant="body2" sx={{ color: formData.colors.body.text }}>
                            Free
                        </Typography>
                    </Box>
                    <Box sx={{ backgroundColor: formData.colors.background.border, height: '1px', my: 1.5 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
                        <Typography variant="body1" sx={{ color: formData.colors.body.text, fontWeight: 'bold' }}>
                            Total
                        </Typography>
                        <Typography variant="body1" sx={{ color: formData.colors.body.text, fontWeight: 'bold' }}>
                            RM599.97
                        </Typography>
                    </Box>
                </Box>
            </PreviewPaper>

            {/* Color Picker Popover */}
            <Popover
                open={Boolean(colorPickerAnchor)}
                anchorEl={colorPickerAnchor}
                onClose={() => {
                    setColorPickerAnchor(null);
                    setActiveColorField(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ChromePicker
                    color={activeColorField ? formData.colors[activeColorField.group][activeColorField.field] : '#fff'}
                    onChange={handleColorChange}
                />
            </Popover>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Checkout;