import React, { useState, useEffect } from 'react';
import { ArrowLeft, AlertTriangle, Copy } from 'lucide-react';
import { Box, Typography, Button, IconButton, TextField, Paper, Alert, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useKeycloak } from '../../../../contexts/KeycloakContext';
import { useUserInfo } from '../../../../hooks/useUserInfo';
import { authService } from '../../../../services/auth.service';

const DomainVerification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, isLoading } = useKeycloak();
    const { userInfo } = useUserInfo();

    // Extract data from location state
    const { id, domain, type, organizationId } = location.state || {};

    const queryParams = new URLSearchParams(location.search);

    const [currentDomain, setCurrentDomain] = useState(domain || '');
    const [isEditable, setIsEditable] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsEditable(type !== 'Redirect' || domain === "newDomain");
    }, [type, domain]);

    const dnsRecords = [
        {
            type: 'CNAME',
            name: currentDomain || '[Your Domain]',
            ttl: '60 mins',
            currentValue: '1dsfa34.eventlah.com'
        }
    ];

    const handleCopyValue = (value) => {
        navigator.clipboard.writeText(value);
    };

    const handleDomainChange = (event) => {
        setCurrentDomain(event.target.value);
    };

    const handleConnectDomain = async () => {
        setIsSubmitting(true);
        setError(null);
        try {
            // Ensure we have a valid token before making the request
            await authService.ensureValidToken();
            
            const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/organization-domains`;

            const domainData = {
                organization_id: organizationId || userInfo?.organization_id,
                name: currentDomain,
                type: type || 'Domain',
                status: 'active'
            };

            // Get token for the request
            const token = await authService.getAccessToken();

            let response;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            if (id === 'newDomain') {
                response = await axios.post(apiUrl, domainData, config);
            } else {
                response = await axios.put(`${apiUrl}?id=${id}`, domainData, config);
            }

            if (response.data.code === 201 || response.data.code === 200) {
                navigate('/settings/domain-setup');
            } else {
                throw new Error(response.data.message || 'Failed to connect domain');
            }
        } catch (err) {
            setError(err.message || 'An error occurred while connecting the domain');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </IconButton>
                <Typography variant="h6" sx={{ mr: 2 }}>Domain Verification</Typography>
            </Box>

            {error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                    {error}
                </Alert>
            )}

            <Box sx={{border: '1px solid rgba(169, 169, 169, 0.3)', borderRadius: '10px', boxShadow: '0 0 10px rgba(128, 128, 128, 0.2)', mb: 3}}>
                <Alert severity="warning" icon={<AlertTriangle />} sx={{display: 'flex', alignItems: 'center', borderRadius: '10px 10px 0 0'}}>
                    Warning: This domain requires verifications
                </Alert>
                <Box sx={{ bgcolor: 'background.paper', borderRadius: '0 0 10px 10px', p: 2 }}>
                    <Typography variant="subtitle1">
                        Please ensure the DNS records match the required values for successful domain verification.
                    </Typography>
                </Box>
            </Box>

            <Paper elevation={1} sx={{ mb: 3, p: 2, borderRadius: 2, border: '1px solid rgba(169, 169, 169, 0.3)' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {type === 'Redirect' ? 'Step 1: Your Fixed Subdomain' : 'Step 1: Enter Domain'}
                </Typography>
                {type !== 'Redirect' || domain === 'newDomain' ? (
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Domain"
                        value={currentDomain === 'newDomain' ? '' : currentDomain}
                        onChange={handleDomainChange}
                        placeholder="Enter your domain"
                        sx={{ mb: 2 }}
                    />
                ) : (
                    <Typography>{currentDomain === 'newDomain' ? '' : currentDomain}</Typography>
                )}
            </Paper>

            <Paper elevation={1} sx={{ mb: 3, p: 2, borderRadius: 2, border: '1px solid rgba(169, 169, 169, 0.3)' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Step 2: Log in to Your Domain Registrar</Typography>
                <Typography>
                    Log in to your domain registrar's website to access DNS settings for {currentDomain || 'your domain'}.
                </Typography>
            </Paper>

            <Paper elevation={1} sx={{ mb: 3, p: 2, borderRadius: 2, border: '1px solid rgba(169, 169, 169, 0.3)' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Step 3: Update DNS Records</Typography>
                <Typography sx={{ mb: 2 }}>
                    Update the DNS records for {currentDomain || 'your domain'} with the following values:
                </Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>TTL</TableCell>
                            <TableCell>Content</TableCell>
                            <TableCell padding="none" width={48} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dnsRecords.map((record, index) => (
                            <TableRow key={index}>
                                <TableCell>{record.type}</TableCell>
                                <TableCell>{record.name}</TableCell>
                                <TableCell>{record.ttl}</TableCell>
                                <TableCell>{record.currentValue}</TableCell>
                                <TableCell padding="none">
                                    <IconButton size="small" onClick={() => handleCopyValue(record.currentValue)}>
                                        <Copy size={16} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConnectDomain}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Connecting...' : 'Save'}
                </Button>
            </Box>
        </Box>
    );
};

export default DomainVerification;